// counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem('language') || "en";

// Redux Toolkit slice
export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    changeLanguage: (state) => {
      if (state == "en") {
        localStorage.setItem('language', "ar");
        return "ar"
      } else {
        localStorage.setItem('language', "en");
        return "en"
      }
    },
  },
});
export const { changeLanguage } = languageSlice.actions;

export default languageSlice.reducer;
