import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as CallIcon } from "../../images/call.svg";
import { ReactComponent as UserIcon } from "../../images/user.svg";
import { ReactComponent as LanguageIcon } from "../../images/language.svg";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { changeLanguage } from "../../redux/slices/LanguageSlice";

import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import { desktopSizes } from "../../util/sizes";

const Header = ({}) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const dispatch = useDispatch();
  return language === "en" ? (
    <StyledHeader className="desktop-only">
      <div className="col1">
        <CallIcon />
        <div className="callnumber">+996512345679</div>
      </div>
      <div className="col2">
        <button
          className="link-group"
          onClick={() => {
            dispatch(changeLanguage());
          }}
        >
          <LanguageIcon />
          <p>{t.header.lang}</p>
        </button>
        <Link to="/sign-up" className="link-group">
          <UserIcon />
          <p>{t.header.signup}</p>
        </Link>
      </div>
    </StyledHeader>
  ) : (
    <StyledArabicHeader className="desktop-only">
      <div className="col1">
        <CallIcon />
        <div className="callnumber">+996512345679</div>
      </div>
      <div className="col2">
        <button
          className="link-group"
          onClick={() => {
            dispatch(changeLanguage());
          }}
        >
          <LanguageIcon />
          <p>{t.header.lang}</p>
        </button>
        <Link to="/sign-up" className="link-group">
          <UserIcon />
          <p>{t.header.signup}</p>
        </Link>
      </div>
    </StyledArabicHeader>
  );
};
const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.111vw 10.417vw;
  background: #fff;
  .col1 {
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 0.556vw;
    .callnumber {
      font-weight: 600;
      font-size: 0.972vw;
      line-height: 1.667vw;
      color: #0d0a19;
      @media (min-width: 1440px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  .col2 {
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 1.806vw;
    button {
      background: none;
    }
    .link-group {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.417vw;
      svg {
        width: 1.25vw;
        height: 1.25vw;
      }
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: #0d0a19;

        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
`;

const StyledArabicHeader = styled.div`
  direction: rtl;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.111vw 10.417vw;
  background: #fff;
  .col1 {
    z-index: 1;
    direction: rtl;
    flex-direction: row-reverse;
    display: flex;
    align-items: center;
    gap: 0.556vw;
    .callnumber {
      direction: ltr;
      font-weight: 600;
      font-size: 0.972vw;
      line-height: 1.667vw;
      color: #0d0a19;

      @media (min-width: 1440px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  .col2 {
    z-index: 1;
    direction: rtl;
    display: flex;
    align-items: center;
    gap: ${desktopSizes._32px};
    button {
      background: none;
    }
    .link-group {
      direction: rtl;
      flex-direction: row-reverse;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.417vw;
      svg {
        width: 1.25vw;
        height: 1.25vw;
      }
      p {
        font-family: GE-SS-Bold;
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: #0d0a19;

        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
`;

export default Header;
