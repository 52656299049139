import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Close } from "../../images/black-close.svg";
import SelectMenu from "../assets/SelectMenu";
import { ReactComponent as Eye } from "../../images/eye.svg";
import { ReactComponent as ClosedEye } from "../../images/eye-close.svg";

import logo from "../../images/logo.svg";

import Modal from "../assets/Modal";
import StyledForm from "../assets/StyledForm";
import { desktopSizes } from "../../util/sizes";
import { countriesCodes } from "../../util/data";
import StyledModal from "./StyledModal";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const LoginModal = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [passwordType, setPasswordType] = useState("password");
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  useEffect(() => {}, []);
  const codeField = {
    name: "code",
    options: countriesCodes,
  };

  return (
    <Modal urlKey={"login"}>
      <StyledModal>
        <div className="header">
          <button
            className="close-btn"
            onClick={() => {
              navigate(location.pathname);
            }}
          >
            <Close />
          </button>
          <Link to="/">
            <img src={logo} className="logo" alt="logo" />
          </Link>
        </div>
        <div className="title-text">
          <p className="title">{t.carPage.loginModal.title}</p>
          <p className="text">{t.carPage.loginModal.text}</p>
        </div>
        <div className="form-container">
          <StyledForm
            onSubmit={(e) => {
              e.preventDefault();
              navigate(location.pathname + "?quotation-success=true");
            }}
          >
            <div className="fields">
              <div className="input-div" key="mobile">
                <div className="mobile-input-div">
                  {formData["mobile"] && (
                    <p className="label">{t.carPage.mobile}</p>
                  )}
                  <input
                    name="mobile"
                    placeholder={t.carPage.mobile}
                    type={"number"}
                    required={true}
                    onChange={(e) =>
                      setFormData({
                        ...formData,

                        mobile: e.target.value,
                      })
                    }
                    value={formData["mobile"] || ""}
                  />
                  <div className="select-code">
                    <SelectMenu
                      field={codeField}
                      value={formData.countryCode}
                      setValue={(value) => {
                        setFormData({
                          ...formData,

                          countryCode: value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="input-div" key="password">
                {formData["password"] && (
                  <p className="label">{t.carPage.password}</p>
                )}
                <input
                  name="password"
                  placeholder={t.carPage.password}
                  type={passwordType}
                  required={true}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      password: e.target.value,
                    })
                  }
                  className={`password-input ${
                    formData["password"] ? "label_active" : ""
                  }`}
                  value={formData["password"] || ""}
                />
                <button
                  className="password-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    passwordType == "password"
                      ? setPasswordType("text")
                      : setPasswordType("password");
                  }}
                >
                  {passwordType == "password" ? <ClosedEye /> : <Eye />}
                </button>
              </div>
            </div>
            <div className="btn-container">
              <button className="submit" type="submit">
                {t.carPage.LoginandRequest}
              </button>
            </div>
          </StyledForm>
        </div>
        <div className="switch">
          <p>{t.authPages.dontHaveAccount}</p>
          <button
            className="signup"
            onClick={() => {
              navigate(location.pathname + "?signup=true");
            }}
          >
            {t.authPages.sign_up}
          </button>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default LoginModal;
