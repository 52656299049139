import React from "react";
import { Outlet } from "react-router";
import NavBar from "./Components/Home/NavBar";
import Header from "./Components/Home/Header";
import Footer from "./Components/Home/Footer";

const DefaultContainer = () => {
  return (
    <>
      <Header />
      <NavBar />
      <Outlet />
      <Footer />
    </>
  );
};

export default DefaultContainer;
