import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Eye } from "../images/eye.svg";
import { ReactComponent as ClosedEye } from "../images/eye-close.svg";
import Card from "../Components/Auth/Card";
import logo from "../images/logo.svg";
import { Link } from "react-router-dom";
import { ReactComponent as Google } from "../images/social-media/google.svg";
import { ReactComponent as FB } from "../images/social-media/fb2.svg";
import { ReactComponent as Twitter } from "../images/social-media/twitter.svg";
import StyledForm from "../Components/assets/StyledForm";
import { useSelector } from "react-redux";
import en from "../util/locales/en";
import ar from "../util/locales/ar";
import StyledAuthPage from "../Components/Auth/StyledAuthPage";
import { desktopSizes, mobileSizes } from "../util/sizes";
import { useForm } from "react-hook-form";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [passwordType, setPasswordType] = useState("password");
  const isDisabled = () => {
    const disabled = Object.values(formData).some(
      (x) => x === null || x === ""
    );
    return disabled;
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const loginSubmit = handleSubmit((data) => {
    console.log("Login data", data);
  });

  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  function isArabic(text) {
    var pattern = /[\u0600-\u06FF\u0750-\u077F]/;
    let result = pattern.test(text);
    return result;
  }

  useEffect(() => {
    console.log("errors", errors);
  }, [errors]);

  return (
    <StyledPage language={language}>
      <StyledAuthPage>
        <Card page={"login"} />
        <div className="container">
          <Link to="/" className="desktop-only">
            <img src={logo} className="logo" />
          </Link>
          <div className="title">{t.authPages.login_page.header}</div>
          <div className="text desktop-only">{t.authPages.login_page.text}</div>
          <StyledForm
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="fields">
              <div className="input-div" key="email">
                {formData["email"] && (
                  <p className="label">{t.authPages.email_address}</p>
                )}
                <input
                  name="email"
                  placeholder={t.authPages.email_address}
                  type={"text"}
                  required={true}
                  className={`${
                    isArabic(formData["email"]) ? "arabic-input" : ""
                  } ${formData["email"] ? "label_active" : ""}`}
                  value={formData["email"] || ""}
                  {...register("email", {
                    onChange: (e) =>
                      setFormData({
                        ...formData,

                        email: e.target.value,
                      }),
                    required: {
                      value: true,
                      message: "required",
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid",
                    },
                  })}
                />
                 {errors["email"]?.message && (
                <p className="error-text">
                  {errors["email"]?.message != "invalid"
                    ? language == "en"
                      ? `${t.authPages.email} is ${
                          t[errors["email"]?.message]
                        }`
                      : `${t[errors["email"]?.message]} ${t.authPages.email}`
                    : language == "en"
                    ? `${t[errors["email"]?.message]} ${t.authPages.email}`
                    : ` ${t.authPages.email} ${t[errors["email"]?.message]}`}
                </p>
              )}
              </div>
              <div className="input-div" key="password">
                {formData["password"] && (
                  <p className="label">{t.authPages.password}</p>
                )}
                <input
                  name="password"
                  placeholder={t.authPages.password}
                  type={passwordType}
                  required={true}
                  value={formData["password"] || ""}
                  className={`password-input ${
                    formData["password"] ? "label_active" : ""
                  }`}
                  {...register("password", {
                    onChange: (e) =>
                      setFormData({
                        ...formData,
                        password: e.target.value,
                      }),

                    required: {
                      value: true,
                      message: "required",
                    },
                  })}
                />
                <button
                  className="password-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    passwordType == "password"
                      ? setPasswordType("text")
                      : setPasswordType("password");
                  }}
                >
                  {passwordType == "password" ? <ClosedEye /> : <Eye />}
                </button>
                {errors["password"]?.message && (
                  <p className="error-text">
                    {language == "en"
                      ? `${t.authPages.password} is ${
                          t[errors["password"]?.message]
                        }`
                      : `${t[errors["password"]?.message]} ${
                          t.authPages.password
                        }`}
                  </p>
                )}
              </div>
            </div>
            <div className="forget-password">
              <Link to="/forget-password">{t.authPages.forget_password}</Link>
            </div>
            <div className="btn-container">
              <button
                className="submit"
                type="submit"
                onClick={loginSubmit}
                //  disabled={isDisabled()}
              >
                {t.authPages.sign_in}
              </button>
            </div>
          </StyledForm>
          <div className="line">
            <div></div>
            <p>{t.authPages.or}</p>
            <div></div>
          </div>
          <div className="oauth">
            <button>
              <Google />
              <p>{t.authPages.login_page.signup_google}</p>
            </button>
            <button>
              <FB />
              <p>{t.authPages.login_page.signup_fb}</p>
            </button>
            <button>
              <Twitter />
              <p>{t.authPages.login_page.signup_tw}</p>
            </button>
          </div>
          <div className="mobile-footer mobile-only">
            <p>{t.authPages.dontHaveAccount}</p>
            <Link to="/sign-up">{t.authPages.sign_up}</Link>
          </div>
        </div>
      </StyledAuthPage>
    </StyledPage>
  );
};
const StyledPage = styled.div`
  .container {
    form {     
      .fields{
        gap : ${desktopSizes._8px};
      }
      .forget-password {
        margin-top: 1.778vh;
        display: flex;
        justify-content: end;
        a {
          font-family :  ${(props) =>
            props.language == "en" ? "Inter" : "GE-SS-Bold"};
          font-weight:  ${(props) => (props.language == "en" ? "600" : "700")};
          font-size: 0.972vw;
          line-height: 2.333vh;
          color: #154cd6;
          // text-align: right;

          @media(min-width:1440px){
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
    }
    .line {
      display: flex;
      align-items: center;
      gap: 1.111vw;
      margin: 1.667vw 0;
      div {
        width: 12.847vw;
        border: 0.069vw solid #ececf2;
      }
      p {
        font-family :  ${(props) =>
          props.language == "en" ? "Inter" : "GE-SS-Meduim"};
        font-weight:  ${(props) => (props.language == "en" ? "400" : "500")};
        font-size: 0.972vw;
        line-height: 1.778vh;
        color: #9a9ea7;

        @media(min-width:1440px){
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
    .oauth {
      display: flex;
      flex-direction: column;
      gap: 0.417vw;

      button {
        display: flex;
        align-items: center;
        justify-content : start;
        gap: 7.5vw;
        padding : 1.778vh 1.25vw;
        background: #ffffff;
        border: 0.069vw solid #eeeeee;
        border-radius: 0.694vw;
        width: 29.167vw;
        // height: 3.75vw;
        cursor pointer;

        svg {
          width: 1.111vw;
          height: 1.111vw;
          fill: #9a9ea7;
          path {
            fill: #9a9ea7;
          }
          @media(min-width:1440px){
            width: 16px;
            height: 16px;
          }
        }
        p {
          font-family :  ${(props) =>
            props.language == "en" ? "Inter" : "GE-SS-Bold"};
          font-weight:  ${(props) => (props.language == "en" ? "600" : "700")};
          font-size: .972vw;
          line-height: 2.333vh;
          color: #9a9ea7;

          @media(min-width:1440px){
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
     
    }
  }

  @media(max-width : 768px){
    .container {
      form {  
        .fields{
          gap : ${mobileSizes._24px};
        }   
        .forget-password {
          margin-top: ${mobileSizes._8px};
          a {
            font-family :  ${(props) =>
              props.language == "en" ? "Inter" : "GE-SS-Meduim"};
            font-weight:  ${(props) =>
              props.language == "en" ? "400" : "500"};
            font-size: ${mobileSizes._12px};
            line-height: ${mobileSizes._18px};
          }
        }
      }
      .line {
        display: flex;
        align-items: center;
        gap: ${mobileSizes._4px};
        margin: ${mobileSizes._24px} 0;
        div {
          width: 42vw;
          border: ${mobileSizes._1px} solid #ececf2;
        }
        p {
          font-family :  ${(props) =>
            props.language == "en" ? "Inter" : "GE-SS-Meduim"};
          font-weight:  ${(props) => (props.language == "en" ? "400" : "500")};
          font-size: ${mobileSizes._14px};
          line-height:${mobileSizes._21px};
        }
      }
      .oauth {
        display: flex;
        flex-direction: column;
        gap: ${mobileSizes._6px};
  
        button {
          gap: 18.933vw;
          padding : ${mobileSizes._16px} ${mobileSizes._18px};
          border: ${mobileSizes._1px} solid #eeeeee;
          border-radius: ${mobileSizes._10px};
          width: 91.467vw;
          svg {
            width: ${mobileSizes._16px};
            height:  ${mobileSizes._16px};
            fill: #9a9ea7;
            path {
              fill: #9a9ea7;
            }
         
          }
          p {
            font-family :  ${(props) =>
              props.language == "en" ? "Poppins" : "GE-SS-meduim"};
            font-weight: 500;
            font-size: ${mobileSizes._14px};
            line-height: ${mobileSizes._21px};
          }
        }
      }
    }
  }
`;
export default Login;
