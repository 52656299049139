import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { shapes as shapesData } from "../util/data";
import SidebarFilter from "../Components/Cars/SidebarFilter";
import noResult from "../images/no-results.png";
import noResult_AR from "../images/no-results-ar.png";
import { ReactComponent as Filter } from "../images/filter.svg";
import m1 from "../images/models/m1.png";
import m2 from "../images/models/m2.png";
import { fake_car as car } from "./Car";
import ModelsCard from "../Components/Cars/ModelsCard";
import { axiosCarvenui } from "../util/integration";
import { desktopSizes, mobileSizes } from "../util/sizes";
import history from "../util/history";
import { useSelector } from "react-redux";
import en from "../util/locales/en";
import ar from "../util/locales/ar";
import LoadingGif from "../Components/assets/LoadingGif";

const cars = [car, car, car];

const models = [
  {
    id: 1,
    name: "Nissan Patrol 2023",
    logo: m1,
    cars: cars,
  },
  {
    id: 2,
    name: "Nissan Sunny 2023",
    logo: m2,
    cars: cars,
  },
];

const Cars = () => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const location = useLocation();
  const [brands, setBrands] = useState([]);
  const [brandsCount, setBrandsCount] = useState();
  const [loading, setLoading] = useState(false);
  const [shapes, setShapes] = useState([
    "sedan",
    "hatchback",
    "suv",
    "convertible",
    "pickup truck",
    "other",
  ]);
  const [transmissions, setTransmissions] = useState([
    "Automatic",
    "Manual",
    "4x4",
    "Hyprid",
    "Electric",
    "other",
  ]);
  const [fuelTypes, SetFuelTypes] = useState([
    "Petrol",
    "Gasoline",
    "Diesel",
    "Natural Gas",
    "Electric",
  ]);
  const engineCapacities = [1000, 1600, 2000, 2400];

  // console.log("location", location);
  const [brand, setBrand] = useState(null);
  const [shape, setShape] = useState(null);
  // const [selectedBrand, setSelectedBrand] = useState(brand);
  const [selectedBrands, setSelectedBrands] = useState(new Set());
  const [selectedEngineCapacities, setSelectedEngineCapacities] = useState(
    new Set()
  );
  const [selectedFuelTypes, setSelectedFuelTypes] = useState(new Set());
  const [selectedShapes, setSelectedShapes] = useState(new Set());
  const [selectedTransmissions, setSelectedTransmissions] = useState(new Set());
  const [maxPrice, setMaxPrice] = useState();
  const [minPrice, setMinPrice] = useState();
  const [groupedCars, setGroupedCars] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [collapseBrands, setCollapseBrands] = useState(true);
  const [homeSearch, setHomeSearch] = useState(false);
  const [searchKey, setSearchKey] = useState();
  const checkIsMobile = () => window.innerWidth <= 768;
  const [isMobile, setIsMobile] = useState(checkIsMobile());
  const [locationStateIsSet, setLocationStateIsSet] = useState(false);

  const noResult_img = language == "en" ? noResult : noResult_AR;

  useEffect(() => {
    const onResize = () => {
      setIsMobile(checkIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const getFilterData = async () => {
      try {
        setLoading(true);
        const brands_res = await axiosCarvenui.get(`/brand?page_size=7`);

        const brands_data = brands_res.data.results.map((brand) => {
          return {
            ...brand,
            value: brand.id,
          };
        });
        setBrands(brands_data);
        setBrandsCount(brands_res.data.count);
        // const restData_res = await axiosCarvenui.get(`/car/get_details/`);
        // setShapes(restData_res.data.body_types.map((type) => type.body_type));
        // setTransmissions(
        //   restData_res.data.transmissions.map((t) => t.transmission)
        // );
        // SetFuelTypes(restData_res.data.fuel_types.map((f) => f.fuel_type));
        // setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getFilterData();
  }, []);

  const getAllBrands = async () => {
    try {
      let q =
        searchKey != "" && searchKey !== undefined
          ? `/brand?page_size=${brandsCount}&name__icontains=${searchKey}`
          : `/brand?page_size=${brandsCount}`;
      const brands_res = await axiosCarvenui.get(q);

      const brands_data = brands_res.data.results.map((brand) => {
        return {
          ...brand,
          value: brand.id,
        };
      });
      setBrands(brands_data);
      setBrandsCount(brands_res.data.count);
    } catch (error) {
      console.log(error);
    }
  };

  const prepareGroupedCarsWithYear = (resData) => {
    let result = resData.reduce(function (rv, x) {
      let keyToGroup = x["car"]["model"]["brand"]["name"];
      (rv[keyToGroup] = rv[keyToGroup] || []).push(x);

      return rv;
    }, {});

    let arr = [];

    for (let key in result) {
      let b_result = (result[key] = result[key].reduce(function (rv, x) {
        let keyToGroup = x["car"]["model"]["name"];

        (rv[keyToGroup] = rv[keyToGroup] || []).push(x);

        return rv;
      }, {}));
      let models = Object.keys(b_result).map((modelKey) => {
        let m_result = b_result[modelKey].reduce(function (rv, x) {
          let keyToGroup = x["car"]["year"];

          (rv[keyToGroup] = rv[keyToGroup] || []).push(x);

          return rv;
        }, {});

        return Object.keys(m_result).map((mk) => ({
          model_name: modelKey,
          cars: m_result[mk],
        }));
      });

      arr.push({
        brand_name: key,
        models: models.reduce((a, b) => a.concat(b), []),
      });
    }
    return arr;
  };

  const prepareGroupedCars = (resData) => {
    let result = resData.reduce(function (rv, x) {
      let keyToGroup = x["car"]["model"]["brand"]["name"];
      (rv[keyToGroup] = rv[keyToGroup] || []).push(x);

      return rv;
    }, {});

    let arr = [];

    for (var key in result) {
      result[key] = result[key].reduce(function (rv, x) {
        let keyToGroup = x["car"]["model"]["name"];

        (rv[keyToGroup] = rv[keyToGroup] || []).push(x);

        return rv;
      }, {});

      arr.push({
        brand_name: key,
        models: Object.keys(result[key]).map((modelKey) => ({
          model_name: modelKey,
          cars: result[key][modelKey],
        })),
      });
    }
    return arr;
  };

  useEffect(() => {
    const getCars = async () => {
      try {
        setLoading(true);

        const res = await axiosCarvenui.get("car_detail/details_by_car/");
        if (res) {
          // let cars = prepareGroupedCars(res.data);
          let cars = prepareGroupedCarsWithYear(res.data);
          // console.log("cars", cars);
          // console.log("cars2", cars2);
          setGroupedCars(cars);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    };
    if (brand === undefined && shape === undefined) {
      getCars();
    }
  }, [brand]);

  useEffect(() => {
    if (brands.length > 0) {
      if (location.state?.brand) {
        setBrand(location.state.brand);
      } else {
        setBrand();
      }
    }
  }, [location, brands]);

  useEffect(() => {
    if (location.state?.shape) {
      setShape(location.state.shape);
    } else {
      setShape();
    }
  }, [location]);

  useEffect(() => {
    if (shape !== undefined && shape !== null) {
      setSelectedShapes(new Set().add(location.state.shape));
    }
  }, [shape]);

  useEffect(() => {
    if (brands.length > 0) {
      if (brand !== undefined && brand !== null) {
        let selected = brands.find((obj) => {
          return obj.id === brand;
        });

        if (selected) {
          if (!locationStateIsSet) {
            setSelectedBrands(new Set().add(selected));

            if (location.state.brand) {
              setHomeSearch(true);
              if (location.state.type) {
                setSelectedShapes(new Set().add(location.state.type));
              }
              if (location.state.maxPrice) setMaxPrice(location.state.maxPrice);

              if (location.state.minPrice) setMinPrice(location.state.minPrice);
              setLocationStateIsSet(true);
            }
          }
        } else {
          if (!locationStateIsSet) {
            setCollapseBrands(false);
            getAllBrands();
          }
        }
      }
    }
  }, [brand, brands]);

  const handleFilter = async () => {
    // car_detail/?car__body_type__in=convertible,hatchback&car__fuel_type__in=Gasoline&car__model__brand__in=6,7
    let query = "/car_detail/details_by_car/?";
    if (selectedBrands.size > 0) {
      query += `&car__model__brand__in=${[...selectedBrands]
        .map((b) => b.id)
        .join(",")}`;
    }
    if (selectedFuelTypes.size > 0) {
      query += `&car__fuel_type__in=${[...selectedFuelTypes].join(",")}`;
    }

    if (selectedShapes.size > 0) {
      query += `&car__body_type__in=${[...selectedShapes].join(",")}`;
    }
    if (selectedTransmissions.size > 0) {
      query += `&car__transmission__in=${[...selectedTransmissions].join(",")}`;
    }

    if (selectedEngineCapacities.size > 0) {
      query += `&car__engine__in=${[...selectedEngineCapacities].join(",")}`;
    }

    if (maxPrice && minPrice) {
      query += `&car_price__gte=${minPrice}&car_price__lte=${maxPrice}`;
    }

    try {
      setLoading(true);
      const res = await axiosCarvenui.get(query);
      console.log("res after filter", res);
      if (res) {
        let cars = prepareGroupedCarsWithYear(res.data);
        setGroupedCars(cars);

        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      console.log(selectedBrands, "selected ");
      handleFilter();
    }
  }, [
    selectedBrands,
    selectedShapes,
    selectedEngineCapacities,
    selectedFuelTypes,
    selectedTransmissions,
  ]);

  useEffect(() => {
    if (!homeSearch) {
      if (maxPrice && minPrice) {
        setLoading(true);
      }
      const delayDebounceFn = setTimeout(() => {
        if (maxPrice && minPrice) {
          handleFilter();
        } else if (maxPrice == "" && minPrice == "") {
          handleFilter();
        }
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setHomeSearch(false);
    }
  }, [minPrice, maxPrice]);

  const sortBrands = (allBrands) => {
    var ids = new Set([...selectedBrands].map((b) => b.id));

    setBrands([...selectedBrands, ...allBrands.filter((d) => !ids.has(d.id))]);
  };

  const searchBrand = async (value) => {
    let q =
      value != "" && value !== undefined
        ? `/brand?page_size=50&name__icontains=${value}`
        : `/brand?page_size=50`;
    const res = await axiosCarvenui.get(q);
    const data = res.data.results.map((brand) => {
      return {
        ...brand,
        value: brand.id,
      };
    });
    sortBrands(data);
    setBrandsCount(data.length);
  };

  useEffect(() => {
    if (searchKey !== undefined) {
      const delayDebounceFn = setTimeout(() => {
        searchBrand(searchKey);
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchKey]);

  return (
    <StyledPage language={language}>
      <div className="filter-btn-container mobile-only">
        <button
          onClick={() => {
            setShowFilter(!showFilter);
          }}
        >
          <Filter />
          <p>Filter</p>
        </button>
      </div>
      {(!isMobile || showFilter) && (
        <div className="sidebar-container">
          <SidebarFilter
            homeBrand={brand}
            homeShape={shape}
            brands={brands}
            shapes={shapes}
            engineCapacities={engineCapacities}
            fuelTypes={fuelTypes}
            transmissions={transmissions}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
            selectedEngineCapacities={selectedEngineCapacities}
            setSelectedEngineCapacities={setSelectedEngineCapacities}
            selectedFuelTypes={selectedFuelTypes}
            setSelectedFuelTypes={setSelectedFuelTypes}
            selectedTransmissions={selectedTransmissions}
            setSelectedTransmissions={setSelectedTransmissions}
            selectedShapes={selectedShapes}
            setSelectedShapes={setSelectedShapes}
            maxPrice={maxPrice}
            setMaxPrice={setMaxPrice}
            minPrice={minPrice}
            setMinPrice={setMinPrice}
            handleFilter={handleFilter}
            getAllBrands={getAllBrands}
            brandsCount={brandsCount}
            isMobile={isMobile}
            setCollapseBrands={setCollapseBrands}
            collapseBrands={collapseBrands}
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            sortBrands={sortBrands}
          />
        </div>
      )}
      {(!showFilter || !isMobile) &&
        (!loading ? (
          groupedCars.length > 0 ? (
            <div className="cars-list">
              {groupedCars.map((group) => (
                <div className="brand">
                  <div className="brand-title">{group.brand_name}</div>
                  <div className="models">
                    {group.models.map((model) => (
                      <ModelsCard model={model} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="no-result">
              <img src={noResult_img} />
            </div>
          )
        ) : (
          <div className="loading-div">
            <LoadingGif />
          </div>
        ))}
    </StyledPage>
  );
};
const StyledPage = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  padding: 1.944vw 10.417vw 3.333vw 10.417vw;
  display: flex;
  align-items: flex-start;
  gap: 1.944vw;
  .cars-list {
    display: flex;
    flex-direction: column;
    gap: 1.667vw;
    .brand {
      display: flex;
      flex-direction: column;
      gap: 1.667vw;
      .brand-title {
        width: 51.875vw;
        height: 4.167vw;
        padding: 0.972vw 1.667vw;
        background: rgba(21, 76, 214, 0.05);
        border: 0.069vw solid #e4e6e8;
        border-radius: 0.278vw;
        font-weight: 700;
        font-size: 1.528vw;
        line-height: 2.222vw;
        color: #0d0a19;

        @media (min-width: 1440px) {
          font-size: 22px;
          line-height: 32px;
        }
      }
      .models {
        display: flex;
        flex-direction: column;
        gap: 1.111vw;
      }
    }
  }
  .no-result {
    width: 51.944vw;
    height: 58.194vw;
    background: #ffffff;
    border: ${desktopSizes._1px} solid #e4e6e8;
    border-radius: ${desktopSizes._4px};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 28.889vw;
      height: 19.931vw;
    }

    @media (min-width: 1440px) {
      width: 748px;
      height: 838px;
      img {
        width: 416px;
        height: 287px;
      }
    }
  }
  .loading-div{
    width : 51.875vw;
  }


  @media (max-width: 768px) {
    min-height: 100vh;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0;
    .filter-btn-container {
      width: 99.467vw;
      height: 14.933vw;
      background: #f1f1f1;
      margin: 0 ${mobileSizes._1px} 0 ${mobileSizes._1px};
      padding: ${mobileSizes._16px};

      button {
        cursor: pointer;
        background: none;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        gap: ${mobileSizes._8px};

        svg {
          width: ${mobileSizes._20px};
          height: ${mobileSizes._20px};
        }
        p {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: ${mobileSizes._16px};
          line-height: ${mobileSizes._24px};
          color: #0d0a19;
        }
      }
    }
    .sidebar-container {
      padding: ${mobileSizes._16px} ${mobileSizes._16px} ${mobileSizes._32px}
        ${mobileSizes._16px};
    }
    .cars-list {
      display: flex;
      flex-direction: column;
      gap: ${mobileSizes._16px};
      margin-top: ${mobileSizes._16px};
      padding: ${mobileSizes._16px};
      .brand {
        display: flex;
        flex-direction: column;
        gap: ${mobileSizes._16px};
        .brand-title {
          width: 91.467vw;
          height: 13.6vw;
          padding: ${mobileSizes._16px};

          border: ${mobileSizes._1px} solid #e4e6e8;
          border-radius: ${mobileSizes._4px};
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: ${mobileSizes._16px};
          line-height: ${mobileSizes._19px};
        }
        .models {
          display: flex;
          flex-direction: column;
          gap: ${mobileSizes._16px};
        }
      }
    }
    .no-result {
      flex: 1;
      width: 100vw;
      height: auto;
      background: none;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 70.4vw;
        height: 52.533vw;
      }
    }
  }
`;
export default Cars;
