import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Next } from "../../images/next.svg";
import { ReactComponent as Prev } from "../../images/prev.svg";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const PrevNextPagination = () => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  return (
    <StyledPagination language={language}>
      <button className="disabled">
        <Prev />
      </button>
      <button>
        <Next />
      </button>
    </StyledPagination>
  );
};
const StyledPagination = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  display: flex;
  align-items: center;
  gap: 0.625vw;
  flex-direction: ${(props) => props.language == "ar" && "row-reverse"};
  button {
    width: 3.194vw;
    height: 3.194vw;
    background: #f5f6f7;
    border-radius: 0.694vw;
  }

  svg {
    width: 1.25vw;
    height: 1.25vw;
  }
  .disabled {
    svg {
      opacity: 0.5;
    }
  }

  @media (max-width: 768px) {
    gap: 2.133vw;

    button {
      width: 6.4vw;
      height: 6.4vw;
      border-radius: 1.067vw;
      cursor: pointer;
    }

    svg {
      width: 3.2vw;
      height: 3.2vw;
    }
  }
`;
export default PrevNextPagination;
