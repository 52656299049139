import sedan from "../images/shapes/sedan.png";
import suv from "../images/shapes/suv.png";
import coupe from "../images/shapes/coupe.png";
import hatchback from "../images/shapes/hatchback.png";
import van from "../images/shapes/van.png";
import station from "../images/shapes/station.png";
import egy from "../images/flags/egy.png";
import ksa from "../images/flags/ksa.png";

export const countriesCodes = [
  {
    country: "KSA",
    flag: ksa,
    name: "+966",
    value: "966",
  },
  {
    country: "Egypt",
    flag: egy,
    name: "+20",
    value: "20",
  },
];

export const shapes = [
  {
    img: sedan,
    name: "Sedan",
    value:"sedan"

  },
  {
    img: suv,
    name: "Suv",
    value:"suv"
  },
  {
    img: coupe,
    name: "Coupe",
    value:"other"
  },
  {
    img: hatchback,
    name: "Hatchback",
    value:"hatchback"
  },
  {
    img: van,
    name: "Van",
    value:"other"

  },
  {
    img: station,
    name: "Station",
    value:"other"
  },


];
