export default {
  body_types: {
    sedan: "Sedan",
    hatchback: "Hatchback",
    suv: "Suv",
    convertible: "Convertible",
    "pickup truck": "Pickup Truck",
    other: "Other",
  },
  required:"required",
  invalid:"Invalid",
  passwords_dont_match:"Passwords don't match",
  showMore: "Show More",
  showLess: "Show Less",
  next: "Next",
  calculate: "Calculate",
  taxExcluded: "Tax Excluded",
  totalPrice: "Total Price",
  price: "Price",
  mobileNumber: "Mobile Number",
  sar: "SAR",
  recommended: "RECOMMENDED",
  completed: "Completed",
  years: "Years",
  header: {
    lang: "English",
    signup: "Sign Up",
  },
  navBar: {
    CarsForSale: "Cars For Sale",
    buildCar: "Build Your Car",
    carFinance: "Car Finance",
    reviews: "Car Reviews",
  },
  intro: {
    title: "Find your next awesome Car ...",
    title_l1: "Find your next",
    title_l2: "awesome Car ...",
    text: "Search for you preferred sedan, hatchback and suv cars from +54 international brand",
    text_l1: "Search for you preferred sedan, hatchback",
    text_l2: "and suv cars from +54 international brand",
    search: {
      brand: "Brand",
      model: "Model",
      type: "Type",
      price: "Price",
      search: "Search",
    },
  },
  brands: {
    title: "Explore Cars by Top Brands",
    mobileTitle: "Explore Top Brands",
    carsAvalibale: "Cars Available",
  },
  buildCar: {
    title: "Build Your Car ",
    text: "Customize your car by selecting your preferred Brand, Model, Type, Body Shape, Color, Custom Trim, Transmission, Engine Capacity and Fuel Type",
    button: "Let’s Build a Car",
  },
  shapes: {
    title: "Explore by Body Shape",
  },
  calculator: {
    title: "Car Loan Calculator",
    brand: "Brand",
    model: "Model",
    year: "Production Year",
    color: "Body Color",
    type: "Car Type",
    button: "Calculate Your Loan",
  },
  carCard: {
    price: "Price",
    seller: "Seller",
  },
  sellers: {
    topSellers: "Top Sellers",
  },
  news: {
    latestNews: "Latest News",
  },
  reviews: {
    carvinuReviews: "Carvinu Reviews ",
    review: "REVIEW",
    readMore: "Read More",
    readless: "Read Less",
  },
  footer: {
    text: " F-Series captures America’s best-selling truck crown for 46th straight year, Ford posts record electric vehicles sales, harnesses no. 2 ev automaker spot, ford expands total market",
    privacyPolicy: "Privacy Policy",
    termUse: "Term of Use",
  },
  carsFilter: {
    detailedSearch: "Detailed Search",
    carBrand: "Car Brand",
    searchforBrand: "Search for Brand ...",
    viewAllBrands: "View All Brands",
    viewLess: "View Less",
    price: "Price",
    from: "From",
    to: "To",
    transmission: "Transmission",
    bodyShape: "Body Shape",
    fuelType: "Fuel Type",
    engineCapacity: "Engine Capacity",
    viewResult: "View Result",
    clear: "Clear Filters",
  },
  carFinanceSteps: {
    carDetails: "Car Details",
    personalInfo: "Personal Info",
    workInfo: "Work Info",
    financialInfo: "Financial Info",
    loansInfo: "Loans Info",
  },
  carFinance: {
    carDetails: {
      brand: "Brand",
      model: "Model",
      year: "Production Year",
      color: "Body Color",
      interiorMaterial: "Interior Material",
      materialColor: "Material Color",
      seller: "Seller",
    },
    personalInfo: {
      fname: "First Name",
      lname: "Last Name",
      email: "Email Address",
      mobileNumber: "Mobile Number",
      nationality: "Nationality",
      nationalID: "National ID",
    },
    workInfo: {
      workType: "Work Type",
      workDuration: "Work Duration",
      salary: "Salary",
      payrollBank: "Payroll Bank",
    },
    financialInfo: {
      food_expense: "Food Expense",
      wages_for_domestics: "Wages for Domestics",
      housing_rent: "Housing (RENT)",
      education_expense: "Education Expense",
      health_care_expense: "Healthcare Expense",
      transport_and_communication_expense: "Transport & communication expense",
      insurance_expense: "Insurance Expense",
      other_expense: "Other Expense",
    },
    loanInfo: {
      homeFinance: "Home Finance",
      personalFinance: "Personal Finance",
      SocialBank_others: "Social Bank & others",
      autoLease: "Auto Lease",
      creditCards: "Credit Cards",
    },
    result: {
      contact_alert: "We will contact you shortly",
      banksOffers: "Banks Offers",
      plans: "Plans",
      installment: "Installment",
      year: "Years",
      downPayment: "Down Payment ",
      loanAmount: "Loan Amount",
      adminFees: "Admin Fees",
      tenure: "Tenure",
      lastBatch: "Last Batch",
      bookNow: "Book Now",
      MonthlyInstallment: "Monthly Installment",
    },
  },
  buildCarPage: {
    step_title: "Select you preferred",
    brand: "Brand",
    engineCapacity: "Engine Capacity",
    carTrim: "Car Trim",
    gearBox: "Gear Box",
    paymentMethod: "Payment Method",

    sectionCard: {
      title_line1: "Build Your",
      title_line2: "Custom Car",
    },
  },
  carPage: {
    price: {
      starting: "Starting",
      text: "Price may be changed based on customization",
    },
    calculator: {
      header: "Calculate Loan",
      text: "Create your custom loan plan",
    },
    specifications: {
      specifications: "Specifications",
      body_type: "Body type",
      body_volume: "Body Volume",
      tank: "Tank",
      engine: "Engine",
      seats: "Seats",
      liters: "Liters",
      cylinders: "-Cylinders",
    },
    warranty: {
      title: "Warranty Coverage",
      warranty: "Warranty",
      distance: "Distance",
      km: "Km",
      years: "Years",
    },
    features: {
      title: "Features",
      fuel_type: "Fuel Type",
      trim: "Trim",
      internal_toolkit: "Internal Tool Kit",
      engine: "Engine",
    },
    availability: {
      title: " Find Your Preferred Car In",
      car_customization: "Car Customization",
      body_color: "Body Color",
      interior_material: "Interior Material",
      interior_color: "Interior Material Color",
      footer_text:
        "You can request quotation to book this car or start your loan calculation",
      calculateLoan: "Calculate Loan",
      requestQuotation: "Request Quotation",
    },
    fullname: "Full Name",
    mobile: "Mobile Number",
    createPassword: "Create Password",
    password: "Password",
    LoginandRequest: "Login & Request Quotation",
    registerModal: {
      title: "You need to have an account ",
      text: "Please enter info below to get back to you with requested quotation ",
    },
    loginModal:{
      title:"You need to have to log in",
      text:"Please enter info below to get back to you with requested quotation "
    },
    quotationModal:{
      title:"Request received successfully",
      text:"One of our team will contact you shortly",
      Okay:"Okay",
      useLoanCalc:" Use Loan Calculator",
    },
   
  },
  carReviewsPage: {
    title: "Car Reviews",
    latestReviews: "Latest Reviews",
    allReviews: "All Reviews",
  },
  authPages: {
    haveAcount: "Already have an account?",
    dontHaveAccount: "Don’t have an account?",
    sign_up: "Sign Up",
    sign_in: "Sign In",
    login: "Login",
    forget_password: "Forgot Password?",
    email: "Email",
    fname: "First name",
    lname: "Last name",
    mobile: "Mobile Number",
    password: "Password",
    confirm_password: "Confirm password",
    new_password: "New Password",
    confirm_new_password: "Confirm New Password",
    email_address: "Email Address",
    or: "Or",
    card: {
      text_line1: "A new way",
      text_line2: "to buy and sell cars",
    },
    sign_up_page: {
      header: "Tell us about yourself",
      text: "Enter your details to create your own account",
      agreeTerms: "I agree with terms & conditions",
      continue: "Continue",
      success_title: "Thank you!",
      success_txt_p1: "We sent an email to  ",
      success_txt_p2:
        "Click confirmation link in the email to verify your account",
    },
    login_page: {
      header: "Sign In to your account",
      text: "Enter your details to proceed",
      signup_google: "Sign Up with Google",
      signup_fb: "Sign Up with Facebook",
      signup_tw: "Sign Up with Twitter",
    },
    forget_password_page: {
      recovery: {
        title_line1: "Forgot your password?",
        title_line2: " Enter your details to recover",
        text: "Enter your details to proceed further",
        recover: "Recover",
      },
      verify: {
        title_l1: "Verify your account",
        title_l2: "Enter 4 digits sent to your email",
        text: "Enter your details to proceed further",
        verify: "Verify",
      },
      reset_password: {
        title: "Reset your password",
        text: "Enter your new password and its confirmation",
        setPassword: "Set Password",
      },
    },
  },
};
