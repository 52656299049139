import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const OverFlowDiv = ({ text }) => {
    const language = useSelector((state) => state.language);
    const t = language === "en" ? en : ar;
    const [nameOverflow, setNameOverflow] = useState();
    const nameRef = useRef();
  
    useEffect(() => {
      if (nameRef.current?.scrollWidth > nameRef.current?.clientWidth) {
        setNameOverflow(true);
      }
    }, [nameRef.current]);
    return (
      <div
        className="name"
        ref={nameRef}
        style={nameOverflow && language == "ar" ? { direction: "ltr" } : {}}
      >
        {text}
      </div>
    );
  };

export default OverFlowDiv;