import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logo from "../../images/logo.svg";
import ProgressStepper from "../assets/ProgressStepper";
import ItemCard from "./ItemCard";
import SectionCard from "./SectionCard";
import { Link } from "react-router-dom";
import { mobileSizes } from "../../util/sizes";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const BuildStep = ({
  step,
  setStep,
  item,
  itemValues,
  image,
  selectValue,
  selected,
}) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  return (
    <StyledPage language={language}>
      {step.id}
      <div className="col1">
        <Link to="/" className="desktop-only">
          <img src={logo} className="logo" />
        </Link>
        <div className="stepper">
          <ProgressStepper steps={5} currentStep={step} />
        </div>
        <div className="items-title">
          {t.buildCarPage.step_title} "{item}"
        </div>
        <div className="item-values">
          {itemValues.map((value, i) => (
            <ItemCard
              onClick={() => {
                if (step < 5) {
                  setTimeout(() => {
                    setStep(step + 1);
                  }, 200);
                  // setStep(step + 1);
                }
                selectValue(value.name);
              }}
              isSelected={selected == value.name}
              key={i}
              itemValue={value?.name}
              image={step == 1 ? value?.logo : null}
              step={step}
            />
          ))}
        </div>
      </div>
      <div className="col2 desktop-only">
        <SectionCard image={image} />
      </div>
    </StyledPage>
  );
};
const StyledPage = styled.div`
  direction : ${(props) => (props.language == "en" ? "ltr" : "rtl")};

  @keyframes fade-in {
    0% {
      opacity: 0;
      transform: translateX(-50%);
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fade-in-right {
    0% {
      opacity: 0;
      transform: translateX(50%);
    }
    100% {
      opacity: 1;
    }
  }
  // transition:. right 1s ease-in-out;

  display: flex;
  align-items: flex-start;
  gap: 1.944vw;
  padding : ${(props) =>
    props.language == "en"
      ? "2.222vw 2.222vw 2.222vw 10.417vw"
      : "2.222vw 2.222vw 2.222vw 10.139vw"};
  .col1 { 
    .logo {
      width: 8.264vw;
      height: 2.083vw;
      margin-bottom: 2.222vw;

      @media(min-width:1440px){
        width: 119px;
        height: 30px;
      }
    }

    .stepper {
      .steps {
        gap: 0.139vw;

        .step {
          width: 7.569vw;
        }
      }
    }

    .items-title {
      font-family: ${(props) =>
        props.language == "en" ? "Poppins" : "GE-SS-Bold"};
      font-style: normal;
      font-weight: 700;
      font-size: 1.667vw;
      line-height: 3.333vw;
      color: #0d0a19;
      margin-top: 3.333vw;
      opacity: 0;
      animation:  ${(props) =>
        props.language == "en"
          ? "fade-in 1s ease-in forwards"
          : "fade-in-right 1s ease-in forwards"};
      
      @media(min-width:1440px){
        font-size: 24px;
        line-height: 48px;
      }
    }
    .item-values {
      opacity: 0;
      animation:  ${(props) =>
        props.language == "en"
          ? "fade-in 1s ease-in forwards"
          : "fade-in-right 1s ease-in forwards"};
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 1.667vw;
      column-gap: 1.944vw;
      margin-top: 1.667vw;
    }
  }

  @media (max-width: 768px) {
    padding: ${mobileSizes._8px} ${mobileSizes._16px} ${mobileSizes._24px};
    .col1 {
      .logo {
        width: 8.264vw;
        height: 2.083vw;
        margin-bottom: 2.222vw;
      }

      .stepper {
        .steps {
          gap: ${mobileSizes._6px};
          .step {
            width: 17.067vw;
          }
        }
      }

      .items-title {
        font-weight: 600;
        font-size: ${mobileSizes._16px};
        line-height: ${mobileSizes._24px};
        margin-top: ${mobileSizes._24px};
        opacity: 0;
        animation:  ${(props) =>
          props.language == "en"
            ? "fade-in 1s ease-in forwards"
            : "fade-in-right 1s ease-in forwards"};
      }
      .item-values {
        opacity: 0;
        animation:  ${(props) =>
          props.language == "en"
            ? "fade-in 1s ease-in forwards"
            : "fade-in-right 1s ease-in forwards"};
        display: flex;
        flex-direction: column;
        gap : ${mobileSizes._12px}
        margin-top: ${mobileSizes._16px};
      }
    }
  }
`;
export default BuildStep;
