import React, { useEffect, useState } from "react";
import Form from "../assets/Form";

import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const LoansInfoForm = ({ onSubmit, ...props }) => {
  const [formData, setFormData] = useState({});
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  const home_finance_field = {
    name: "home_finance",
    displayName: t.carFinance.loanInfo.homeFinance,
    placeholder: "0",
    displayCurrency: true,
    required: false,
    type: "number",
  };

  const personal_finance_field = {
    name: "personal_finance",
    displayName: t.carFinance.loanInfo.personalFinance,
    placeholder: "0",
    displayCurrency: true,
    required: false,
    type: "number",
  };

  const social_bank_others_field = {
    name: "social_bank_and_other_field",
    displayName: t.carFinance.loanInfo.SocialBank_others,
    placeholder: "0",
    displayCurrency: true,
    required: false,
    type: "number",
  };

  const auto_lease_field = {
    name: "auto_lease",
    displayName: t.carFinance.loanInfo.autoLease,
    placeholder: "0",
    displayCurrency: true,
    required: false,
    type: "number",
  };

  const credit_card_field = {
    name: "credit_card",
    displayName: t.carFinance.loanInfo.creditCards,
    placeholder: "0",
    displayCurrency: true,
    required: false,
    type: "number",
  };

  const fields = [
    home_finance_field,
    personal_finance_field,
    social_bank_others_field,
    auto_lease_field,
    credit_card_field,
  ];
  const keys = fields.map((field) => field.name);

  return (
    <Form
      formData={formData}
      setFormData={setFormData}
      fields={fields}
      submitText={t.calculate}
      onSubmit={async () => {
        let o = {};
        await Promise.all(
          keys.map((item) => {
            if (!formData[item]) {
              o[item] = 0;
            }
          })
        );
        // setFormData({ ...values, ...formData });
        console.log("formdat", formData);

        onSubmit({ ...formData, ...o });
      }}
    />
  );
};

export default LoansInfoForm;
