import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Card from "../Components/Auth/Card";
import key from "../images/auth/key.svg";
import Recover from "../Components/Auth/ForgetPassword/Recover";
import VerifyAccount from "../Components/Auth/ForgetPassword/VerifyAccount";
import ResetPassword from "../Components/Auth/ForgetPassword/ResetPassword";
import { useSelector } from "react-redux";
import en from "../util/locales/en";
import ar from "../util/locales/ar";
import { mobileSizes } from "../util/sizes";

const ForgetPassword = () => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  const [step, setStep] = useState(1);
  const [recoverformData, setRecoverFormData] = useState({
    email: "",
  });

  const [digits, setDigits] = useState({
    digit_1: "",
    digit_2: "",
    digit_3: "",
    digit_4: "",
  });

  const [restData, setResetData] = useState({
    password1: "",
    password2: "",
  });

  const isDisabled = (data) => {
    const disabled = Object.values(data).some((x) => x === null || x === "");
    return disabled;
  };

  const submitRecover = () => {
    setStep(2);
  };

  const verifySubmit = () => {
    setStep(3);
  };

  const resetSubmit = () => {};
  return (
    <StyledPage language={language}>
      <Card />

      {step == 1 ? (
        <Recover
          onSubmit={submitRecover}
          formData={recoverformData}
          setFormData={setRecoverFormData}
          isDisabled={isDisabled(recoverformData)}
        />
      ) : step == 2 ? (
        <VerifyAccount
          onSubmit={verifySubmit}
          isDisabled={isDisabled(digits)}
          formData={digits}
          setFormData={setDigits}
        />
      ) : (
        step == 3 && (
          <ResetPassword
            onSubmit={resetSubmit}
            formData={restData}
            setFormData={setResetData}
            isDisabled={isDisabled(restData)}
          />
        )
      )}
    </StyledPage>
  );
};
const StyledPage = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  display: flex;
  align-items: center;
  gap: 10.903vw;
  padding: 2.222vh 2.083vw 0 2.083vw;
  background: #fff;

  .container {
    // width: 29.167vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    .section-icon {
      width: 12.569vw;
      height: 8.611vw;
      margin-bottom: 1.667vw;
    }
    .title {
      margin-bottom: 0.833vw;
      text-align: center;
      p {
        font-family: ${(props) =>
          props.language == "en" ? "Poppins" : "GE-SS-Bold"};
        font-style: normal;
        font-weight: ${(props) => (props.language == "en" ? "600" : "700")};
        font-size: 1.667vw;
        line-height: 2.5vw;
        color: #0d0a19;
        @media (min-width: 1440px) {
          font-size: 24px;
          line-height: 36px;
        }
      }
    }
    .text {
      font-family: ${(props) =>
        props.language == "en" ? "Poppins" : "GE-SS-Meduim"};
      font-style: normal;
      font-weight: 500;
      font-size: 0.972vw;
      line-height: 1.458vw;
      color: #9a9ea7;
      margin-bottom: 1.667vw;
      @media (min-width: 1440px) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: ${mobileSizes._12px} ${mobileSizes._16px} ${mobileSizes._24px}
      ${mobileSizes._16px};
    background: #fbfbfb;
    .container {
      .section-icon {
        width: 48.267vw;
        height: 33.067vw;
        margin-bottom: ${mobileSizes._24px};
      }
      .title {
        margin-bottom: ${mobileSizes._32px};
        text-align: center;
        p {
          font-size: ${mobileSizes._20px};
          line-height: ${mobileSizes._30px};
        }
      }
    }
  }
`;
export default ForgetPassword;
