import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Prev } from "../../images/prev.svg";
import { ReactComponent as Next } from "../../images/next.svg";
import { desktopSizes, mobileSizes } from "../../util/sizes";
import { ReactComponent as Calc } from "../../images/calc.svg";
import { ReactComponent as ArrowRight } from "../../images/arrow-right.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import OverFlowDiv from "../assets/OverFlowDiv";

const CarIntro = ({ name, price, logo, images, loanData, ...props }) => {
  const [index, setIndex] = useState(0);
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const getPrevImg = () => {
    if (index == 0) {
      setIndex(images.length - 1);
    } else {
      setIndex(index - 1);
    }
  };

  const getNextImg = () => {
    if (index == images.length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };
  return (
    <StyledIntro language={language}>
      <div className="slider-div">
        <div className="overlay"></div>
        <img className="main-img" src={images[index]} key={images[index]} />
        <div className="slider-content">
          <button className="arrow-left" onClick={getPrevImg}>
            <Prev />
          </button>
          <p className="index-count">
            {index + 1} / {images.length}
          </p>
          <button className="arrow-right" onClick={getNextImg}>
            <Next />
          </button>
        </div>
      </div>
      <div className="col desktop-only">
        <div className="info-card">
          <div className="model card-item">
            <img src={logo} />
            <div className="name-div">
              <div className="first-line">
                {name?.split(" ").splice(0, 2).join(" ")}
              </div>
              <div className="second-line">
                {name?.split(" ").slice(2).join(" ")}
              </div>
            </div>
          </div>
          <div className="price-div card-item">
            <p className="starting">{t.carPage.price.starting}</p>
            <div className="price">
              <div>{price.toLocaleString()} </div>
              <span className="currency"> SAR</span>
            </div>
            <p className="text">{t.carPage.price.text}</p>
          </div>
          <Link
            className="calc card-item"
            to="/car-finance"
            state={{
              calcData: loanData,
            }}
          >
            <div className="col1">
              <Calc />
              <div className="info">
                <p className="text">{t.carPage.calculator.header}</p>
                <p className="text-info">{t.carPage.calculator.text}</p>
              </div>
            </div>
            <ArrowRight />
          </Link>
        </div>
        <div className="images-div">
          <div className="img-container">
            <div className="black-overlay"></div>
            <img
              src={index == 0 ? images[images.length - 1] : images[index - 1]}
              key={index == 0 ? images[images.length - 1] : images[index - 1]}
            />
          </div>
          <div className="img-container">
            <img src={images[index]} key={images[index]} />
          </div>
          <div className="img-container">
            <div className="black-overlay"></div>
            <img
              src={index == images.length - 1 ? images[0] : images[index + 1]}
              key={index == images.length - 1 ? images[0] : images[index + 1]}
            />
          </div>
        </div>
      </div>
      <div className="info-section mobile-only ">
        <div className="model">
          <img src={logo} className="logo" />
          <div className="name">
            <OverFlowDiv text={name} />
          </div>
        </div>
        <div className="price-div">
          <p className="starting">{t.carPage.price.starting}</p>
          <div className="price">
            <div>{price.toLocaleString()} </div>
            <span className="currency"> SAR</span>
          </div>
          <p className="text">{t.carPage.price.text}</p>
        </div>
      </div>
    </StyledIntro>
  );
};
const StyledIntro = styled.div`
  display: flex;
  align-items: center;
  gap: ${desktopSizes._28px};
  .slider-div {
    position: relative;
    .overlay {
      border-radius: ${desktopSizes._12px};
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 78.46%,
        rgba(43, 43, 43, 0.89) 99.92%
      );
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      min-height: 100%;
    }
    width: 51.944vw;
    height: 27.569vw;
    .main-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: ${desktopSizes._12px};
    }
    .slider-content {
      direction: ltr;
      position: absolute;
      left: 45.208vw;
      bottom: ${desktopSizes._16px};
      display: flex;
      align-items: center;
      gap: ${desktopSizes._12px};
      button {
        background: none;
        outline: none;
        border: none;
        cursor: pointer;
        svg {
          width: ${desktopSizes._15px};
          height: ${desktopSizes._15px};
          fill: #ffffff;
          path {
            fill: #ffffff;
          }
          @media (min-width: 1440px) {
            width: 15px;
            height: 15px;
          }
        }
      }
      .index-count {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: ${desktopSizes._12px};
        line-height: ${desktopSizes._15px};
        color: #ffffff;

        @media (min-width: 1440px) {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }
  .col {
    display: flex;
    flex-direction: column;
    gap: ${desktopSizes._24px};
    .info-card {
      width: 25vw;
      height: 19.722vw;
      background: #ffffff;
      padding: ${desktopSizes._8px} 0;
      border: ${desktopSizes._1px} solid #e4e6e8;
      border-radius: ${desktopSizes._8px};
      .card-item {
        padding: ${(props) =>
          props.language == "en"
            ? ` ${desktopSizes._16px} ${desktopSizes._32px}
        ${desktopSizes._16px} ${desktopSizes._24px}`
            : ` ${desktopSizes._16px}  ${desktopSizes._24px}`};
        border-bottom: ${desktopSizes._1px} solid #eeeeee;
      }
      [class~="card-item"]:last-of-type {
        border: none;
      }
      .model {
        display: flex;
        align-items: center;
        gap: ${desktopSizes._12px};
        .name-div {
          display: flex;
          flex-direction: column;
          gap: ${desktopSizes._6px};
          .first-line {
            font-family: "Inter";
            font-style: normal;
            font-weight: 700;
            font-size: ${desktopSizes._24px};
            line-height: ${desktopSizes._28px};

            @media (min-width: 1440px) {
              font-size: 24px;
              line-height: 28px;
            }
          }

          .second-line {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: ${desktopSizes._16px};
            line-height: ${desktopSizes._18px};
            color: #0d0a19;

            @media (min-width: 1440px) {
              font-size: 16px;
              line-height: 18px;
            }
          }
        }

        img {
          width: 3.333vw;
          height: 3.333vw;
          object-fit: contain;

          @media (min-width: 1440px) {
            width: 48px;
            height: 48px;
          }
        }
      }

      .price-div {
        .starting {
          font-family: ${(props) =>
            props.language == "en" ? "Inter" : "GE-SS-UltraLight"};
          font-style: normal;
          font-weight: ${(props) => (props.language == "en" ? "400" : "300")};
          font-size: 0.972vw;
          line-height: 1.181vw;
          margin-bottom: 0.208vw;
          color: #828282;

          @media (min-width: 1440px) {
            font-size: 14px;
            line-height: 17px;
          }
        }
        .price {
          direction: ltr;
          margin-bottom: ${desktopSizes._2px};
          display: flex;
          align-items: flex-end;
          justify-content: ${(props) => props.language == "ar" && "end"};
          gap: 0.556vw;
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: ${desktopSizes._28px};
          line-height: 2.361vw;
          color: #0d0a19;

          @media (min-width: 1440px) {
            font-size: 28px;
            line-height: 34px;
          }

          .currency {
            font-weight: 700;
            font-size: 1.111vw;

            @media (min-width: 1440px) {
              font-size: 16px;
            }
          }
        }
        .text {
          font-family: ${(props) =>
            props.language == "en" ? "Inter" : "GE-SS-UltraLight"};
          font-style: normal;
          font-weight: ${(props) => (props.language == "en" ? "400" : "300")};
          font-size: ${desktopSizes._12px};
          line-height: ${desktopSizes._15px};
          color: #828282;
          @media (min-width: 1440px) {
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
      .calc {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
          width: ${desktopSizes._24px};
          height: ${desktopSizes._24px};
          -webkit-transform: ${(props) =>
            props.language == "ar" && " scaleX(-1)"};
          -moz-transform: ${(props) => props.language == "ar" && " scaleX(-1)"};
          -o-transform: ${(props) => props.language == "ar" && " scaleX(-1)"};
          transform: ${(props) => props.language == "ar" && " scaleX(-1)"};
          @media (min-width: 1440px) {
            width: 24px;
            height: 24px;
          }
        }
        .col1 {
          display: flex;
          align-items: center;
          gap: ${desktopSizes._8px};
          svg {
            width: ${desktopSizes._40px};
            height: ${desktopSizes._40px};

            @media (min-width: 1440px) {
              width: 40px;
              height: 40px;
            }
          }
          .info {
            dispaly: flex;
            flex-direction: column;
            gap: ${mobileSizes._4px};
            .text {
              font-family: ${(props) =>
                props.language == "en" ? "Poppins" : "GE-SS-Meduim"};
              font-style: normal;
              font-family: ${(props) =>
                props.language == "en" ? "600" : "500"};
              font-size: ${desktopSizes._16px};
              line-height: ${desktopSizes._21px};
              color: #154cd6;

              @media (min-width: 1440px) {
                font-size: 16px;
                line-height: 21px;
              }
            }

            .text-info {
              font-family: ${(props) =>
                props.language == "en" ? "Inter" : "GE-SS-UltraLight"};
              font-style: normal;
              font-weight: ${(props) =>
                props.language == "en" ? "400" : "300"};
              font-size: ${desktopSizes._14px};
              line-height: ${desktopSizes._18px};
              color: #828282;
              @media (min-width: 1440px) {
                font-size: 14px;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
    .images-div {
      display: flex;
      align-items: center;
      gap: ${desktopSizes._15px};
      .img-container {
        position: relative;
        width: 7.639vw;
        height: 6.25vw;
        border: ${desktopSizes._1px} solid #e4e6e8;
        border-radius: ${desktopSizes._12px};
        .black-overlay {
          border-radius: ${desktopSizes._12px};
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)
          );
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          min-height: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: ${desktopSizes._12px};
        }
      }
    }
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: initial;
    flex-direction: column;
    gap: ${mobileSizes._18px};
    .slider-div {
      .overlay {
        border-radius: 0;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 70.36%,
          rgba(43, 43, 43, 0.89) 99.89%
        );
      }
      width: 100vw;
      height: 75.733vw;
      .main-img {
        border-radius: 0;
      }
      .slider-content {
        position: absolute;
        left: 39.2vw;
        bottom: ${mobileSizes._14px};
        gap: ${mobileSizes._12px};
        button {
          background: none;
          outline: none;
          border: none;
          cursor: pointer;
          svg {
            width: ${mobileSizes._14px};
            height: ${mobileSizes._14px};
          }
        }
        .index-count {
          font-size: ${mobileSizes._12px};
          line-height: ${mobileSizes._15px};
        }
      }
    }
    .info-section {
      display: flex;
      flex-direction: column;
      gap: ${mobileSizes._16px};
      border-bottom: ${mobileSizes._1px} solid #eee;
      padding: 0 ${mobileSizes._16px} ${mobileSizes._16px} ${mobileSizes._16px};
      .model {
        display: flex;
        align-items: center;
        gap: ${mobileSizes._12px};
        .logo {
          width: ${mobileSizes._24px};
          height: ${mobileSizes._24px};
          object-fit: contain;
        }
        .name {
          color: #0d0a19;
          font-size: ${mobileSizes._18px};
          line-height: ${mobileSizes._27px};
          font-family: Poppins;
          font-weight: 600;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .price-div {
        .starting {
          font-family: ${(props) =>
            props.language == "en" ? "Inter" : "GE-SS-UltraLight"};
          font-style: normal;
          font-weight: ${(props) => (props.language == "en" ? "400" : "300")};
          font-size: ${mobileSizes._14px};
          line-height: ${mobileSizes._18px};
          margin-bottom: 0;
          color: #828282;
        }
        .price {
          direction: ltr;
          justify-content: ${(props) => props.language == "ar" && "end"};
          margin-bottom: 0;
          display: flex;
          align-items: flex-end;
          gap: ${mobileSizes._6px};
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: ${mobileSizes._18px};
          line-height: ${mobileSizes._27px};
          color: #0d0a19;

          .currency {
            font-weight: 700;
            font-size: ${mobileSizes._14px};
          }
        }
        .text {
          font-family: ${(props) =>
            props.language == "en" ? "Inter" : "GE-SS-UltraLight"};
          font-style: normal;
          font-weight: ${(props) => (props.language == "en" ? "400" : "300")};
          font-size: ${mobileSizes._12px};
          line-height: ${mobileSizes._15px};
          color: #828282;
        }
      }
    }
  }
`;

export default CarIntro;
