import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StyledForm from "../../assets/StyledForm";
import key from "../../../images/auth/key.svg";

import { useSelector } from "react-redux";
import en from "../../../util/locales/en";
import ar from "../../../util/locales/ar";
import { desktopSizes, mobileSizes } from "../../../util/sizes";
import { useForm } from "react-hook-form";

const VerifyAccount = ({ onSubmit, formData, setFormData, isDisabled }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const verifySubmit = handleSubmit((data) => {
    console.log("form data", data);
    onSubmit();
  });

  const handleDigitChange = (e, digit) => {
    if (e.target.value != "" && formData[`digit_${digit}`].length > 0) {
      return;
    }
    const value = e.target.value.replace(/\D/g, "");
    setFormData({
      ...formData,
      ["digit_" + digit]: value,
    });

    let name = e.target.name;
    let index = parseInt(name.split("_")[1]);
    // console.log("index", index);
    const nextfield = document.querySelector(`input[name=digit_${index + 1}]`);
    if (nextfield !== null && value !== "") {
      nextfield.focus();
    }
  };

  return (
    <StyledSection>
      <div className="container">
        <img src={key} className="section-icon" />
        <div className="title">
          <p>{t.authPages.forget_password_page.verify.title_l1}</p>
          <p>{t.authPages.forget_password_page.verify.title_l2}</p>
        </div>
        <div className="text desktop-only">
          {t.authPages.forget_password_page.verify.text}
        </div>
        <StyledForm
          onSubmit={(e) => {
            e.preventDefault();
            // onSubmit();
          }}
        >
          <div className="fields digits-fields">
            {[1, 2, 3, 4].map((digit) => (
              <div className="input-div" key={`digit_${digit}`}>
                <input
                  className={`${
                    errors[`digit_${digit}`]?.message ? "input-error" : ""
                  }`}
                  name={`digit_${digit}`}
                  placeholder="-"
                  // type={"number"}
                  type="text"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  maxlength="1"
                  oninput="this.value=this.value.replace(/[^0-9]/g,''"
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                  required={true}
                  onInput={(e) => {
                    handleDigitChange(e, digit);
                  }}
                  {...register(`digit_${digit}`, {
                    valueAsNumber: true,
                    required: {
                      value: true,
                      message: "required",
                    },
                    validate: (value) => {
                      if (!isNaN(value) || formData[`digit_${digit}`]) {
                        return true;
                      } else return "required";
                    },

                    onChange: (e) => {
                      if (
                        e.target.value != "" &&
                        formData[`digit_${digit}`].length > 0
                      ) {
                        return;
                      }
                      const value = e.target.value.replace(/\D/g, "");
                      setFormData({
                        ...formData,
                        ["digit_" + digit]: value,
                      });
                    },
                  })}
                  value={formData[`digit_${digit}`]}
                />
              </div>
            ))}
          </div>
          <div className="btn-container">
            <button
              className="submit"
              type="submit"
              onClick={verifySubmit}
              //  disabled={isDisabled}
            >
              {t.authPages.forget_password_page.verify.verify}
            </button>
          </div>
        </StyledForm>
      </div>
    </StyledSection>
  );
};

const StyledSection = styled.div`
  .container {
    form {
      .digits-fields {
        display: flex;
        flex-direction: row;
        gap: 0.764vw;

        .input-div {
          input {
            width: 6.736vw;
            font-size: 1.389vw;
            line-height: 1.458vw;
            color: #0d0a19;
            text-align: center;
            -webkit-appearance: none;
            -moz-appearance: textfield;
            @media (min-width: 1440px) {
              font-size: 20px;
              line-height: 21px;
            }
          }
          .input-error {
            border: ${desktopSizes._1px} solid #eb0100;
          }
          input::placeholder {
            font-weight: 400;
            font-size: 0.833vw;
            line-height: 1.458vw;
            color: #9a9ea7;
            @media (min-width: 1440px) {
              font-size: 12px;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .container {
      form {
        .digits-fields {
          gap: ${mobileSizes._12px};

          .input-div {
            input {
              width: 20.533vw;
              padding: ${mobileSizes._18px} 8.8vw;
              font-family: Poppins;
              font-weight: 500;
              font-size: ${mobileSizes._16px};
              line-height: ${mobileSizes._24px};
            }
            .input-error {
              border: ${mobileSizes._1px} solid #eb0100;
            }
            input::placeholder {
              font-weight: 400;
              font-size: ${mobileSizes._12px};
              line-height: ${mobileSizes._21px};
            }
          }
        }
      }
    }
  }
`;

export default VerifyAccount;
