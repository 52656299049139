import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import CustomStepper from "../Components/assets/Stepper";
import ProgressStepper from "../Components/assets/ProgressStepper";
import BanksOffers from "../Components/CarFinance/BanksOffers";
import CarDetailsForm from "../Components/CarFinance/CarDetailsForm";
import FinancialInfoForm from "../Components/CarFinance/FinancialInfoForm";
import LoansInfoForm from "../Components/CarFinance/LoansInfoForm";
import PersonalForm from "../Components/CarFinance/PersonalForm";
import WorkInfoForm from "../Components/CarFinance/WorkInfoForm";
import car_img from "../images/car/range-rover.png";
import { axiosCarvenui } from "../util/integration";
import covered_car from "../images/covered-car.png";
import { mobileSizes } from "../util/sizes";
import history from "../util/history";
import axios from "axios";
import { useSelector } from "react-redux";
import en from "../util/locales/en";
import ar from "../util/locales/ar";

const CarFinance = () => {
  const [step, setStep] = useState();
  const [car_detail, setCar_detail] = useState();
  const [personalInfo, setPersonalInfo] = useState({});
  const [workInfo, setWorkInfo] = useState({});
  const [financialInfo, setFinancialInfo] = useState({});
  const [loansInfo, setLoansInfo] = useState({});
  const [banksOffers, setBanksOffers] = useState([]);
  const [displayResults, setDisplayResults] = useState(false);
  const [banks, setBanks] = useState([]);
  const location = useLocation();
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  const steps = [
    t.carFinanceSteps.carDetails,
    t.carFinanceSteps.personalInfo,
    t.carFinanceSteps.workInfo,
    t.carFinanceSteps.financialInfo,
    t.carFinanceSteps.loansInfo,
  ];

  useEffect(() => {
    console.log("location", location);
    if (location.state?.detail) {
      setCar_detail(location.state.detail);
      setStep(1);
    } else {
      setStep(0);
    }
  }, [location]);

  const carDetailsSubmit = async () => {
    setStep(1);
  };

  const PersonalInfoSubmit = (data) => {
    setPersonalInfo({
      ...data,
      mobilephone: data.countryCode.toString() + data.mobile.toString(),
    });
    setStep(2);
  };

  const WorkInfoSubmit = (data) => {
    setWorkInfo(data);
    setStep(3);
  };

  const financialInfoSubmit = (data) => {
    console.log("data", data);
    setFinancialInfo(data);
    setStep(4);
  };

  const CRMCall = async (loan_data) => {
    let date = new Date();
    date.setHours(date.getHours() - 1);

    let utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));

    const utcNow = Date.UTC(
      utcDate.getFullYear(),
      utcDate.getMonth(),
      utcDate.getDate(),
      utcDate.getHours(),
      utcDate.getMinutes(),
      utcDate.getSeconds()
    );

    let crm_res = await axios.post(
      "https://api.hsforms.com/submissions/v3/integration/submit/26768470/8efa3d12-0b9d-4785-8eee-ac794bf2fde3",
      {
        submittedAt: new Date(utcNow).toISOString(),
        fields: [
          {
            name: "email",
            value: personalInfo.email,
          },
          {
            name: "firstname",
            value: personalInfo.first_name,
          },
          {
            name: "lastname",
            value: personalInfo.last_name,
          },
          {
            name: "mobilephone",
            value: personalInfo.mobilephone,
          },
          {
            name: "saudi_non_saudi",
            value: personalInfo.nationality == "Saudi Arabian",
          },
          {
            name: "national_id",
            value: +personalInfo.national_id,
          },
          {
            name: "work_duration_years",
            value: +workInfo.work_duration,
          },
          {
            name: "work_type",
            value: workInfo.work_type,
          },
          {
            name: "monthly_salary",
            value: workInfo.salary,
          },
          {
            name: "payroll_bank",
            value: workInfo.payrol_bank,
          },
          {
            name: "wages_expense",
            value: Object.values(financialInfo).reduce((a, b) => +a + +b, 0),
          },
          {
            name: "food_expense",
            value: Object.values(loan_data).reduce((a, b) => +a + +b, 0),
          },
          {
            name: "numemployee",
            value: car_detail.id,
          },
        ],
        context: {
          pageUri: "https://example.com/contact",
          pageName: "Contact Page",
        },
      },
      {
        headers: {
          Authorization: "Bearer pat-eu1-9bb88938-48d6-4933-aa6c-319108c0652d",
        },
      }
    );
    console.log(crm_res);
  };

  const loansInfoSubmit = async (data) => {
    try {
      setLoansInfo(data);
      const loan_data = {
        car_detail: car_detail.id,
        payrol_bank: workInfo.payrol_bank,
        salary: workInfo.salary,
        retired: workInfo.retired,
        liabilities: {
          ...financialInfo,
          ...data,
        },
      };
      const res = await axiosCarvenui.post(
        `car_detail/${car_detail.id}/loan_request/`,
        loan_data
      );
      if (res) {
        console.log(res.data);
        CRMCall(data);
        setBanksOffers(Object.values(res.data));
        setDisplayResults(true);
        const banksRes = await axiosCarvenui.get(
          `/bank/get/?bank_ids=${Object.keys(res.data).join("&bank_ids=")}`
        );
        if (banksRes) {
          setBanks(banksRes.data);
        }
      }
    } catch (e) {}
  };

  const forms = [
    {
      component: CarDetailsForm,
      submit: carDetailsSubmit,
      props: { setCar_detail: setCar_detail },
    },
    {
      component: PersonalForm,
      submit: PersonalInfoSubmit,
    },
    {
      component: WorkInfoForm,
      submit: WorkInfoSubmit,
    },
    {
      component: FinancialInfoForm,
      submit: financialInfoSubmit,
    },
    {
      component: LoansInfoForm,
      submit: loansInfoSubmit,
    },
  ];

  function RenderForm() {
    // Correct! JSX type can be a capitalized variable.
    const SpecificForm = forms[step].component;
    return (
      <SpecificForm onSubmit={forms[step].submit} {...forms[step].props} />
    );
  }

  return (
    <StyledPage language={language}>
      {displayResults ? (
        banksOffers.length > 0 &&
        banks.length > 0 && (
          <BanksOffers
            offers={banksOffers}
            banks={banks}
            car_detail={car_detail}
          />
        )
      ) : step !== undefined ? (
        <div className="container">
          <div className="stepper desktop-only">
            <CustomStepper steps={steps} activeStep={step} />
          </div>
          <div className="mobile-stepper mobile-only">
            <ProgressStepper steps={5} currentStep={step + 1} />
          </div>
          <div className="card">
            <div className="forms">
              <div className="section-title">{steps[step]}</div>
              <div className="form-container">{RenderForm()}</div>
            </div>

            <div className="detail-container">
              <img
                src={
                  car_detail
                    ? car_detail.car.meta_data.images.image1
                    : covered_car
                }
                className="car-img"
              />
              <div className="price-div desktop-only">
                <p className="text">{t.totalPrice}</p>
                <div className="value-div">
                  <p className="value">
                    {car_detail ? (
                      (+(+car_detail.car_price).toFixed(2)).toLocaleString()
                    ) : (
                      <span className="currency">--</span>
                    )}
                    <span className="currency"> SAR</span>
                  </p>
                  <p className="tax">{t.taxExcluded}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </StyledPage>
  );
};

const StyledPage = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  position: relative;

  .container {
    padding: ${(props) =>
      props.language == "en"
        ? "2.083vw 10.417vw 2.222vw 10.417vw"
        : "2.083vw 9.097vw 2.222vw 10.417vw"};
    display: flex;
    flex-direction: column;
    align-items: center;
    .stepper {
      margin-top: 2.222vw;
    }

    .card {
      margin-top: 2.222vw;
      width: 78.819vw;
      background: #ffffff;
      border: 0.069vw solid #e4e6e8;
      border-radius: 0.278vw;
      display: flex;
      .forms {
        padding: ${(props) =>
          props.language == "en"
            ? "2.222vw 1.667vw 2.222vw 2.222vw"
            : "2.222vw 2.222vw 2.222vw 1.667vw"};
        width: 46.806vw;
        padding-right: ${(props) => props.language == "en" && "1.667vw"};
        padding-left: ${(props) => props.language == "ar" && "1.667vw"};
        border-right: ${(props) =>
          props.language == "en" && "0.069vw solid #e4e6e8"};
        border-left: ${(props) =>
          props.language == "ar" && "0.069vw solid #e4e6e8"};

        .section-title {
          font-family: ${(props) =>
            props.language == "en" ? "Inter" : "GE-SS-Bold"};
          font-style: normal;
          font-weight: 700;
          font-size: 1.389vw;
          line-height: 2.222vw;
          margin-bottom: 2.222vw;
          color: #0d0a19;
          @media (min-width: 1440px) {
            font-size: 20px;
            line-height: 32px;
          }
        }
        .form-container {
          width: 42.917vw;
          form {
            .fields {
              display: grid;
              // grid-template-columns: repeat(2, 1fr);
              grid-template-columns: 48.7% 48.7%;
              grid-auto-flow: row dense;
              row-gap: 1.667vw;
              column-gap: 1.111vw;
              // margin-bottom: 2.222vw;
              .input-div {
                width: 20.903vw;
                .currency {
                  font-family: Poppins;
                }

                input {
                  width: 20.903vw;
                  height: auto;
                  // padding: 1.25vw 2.222vw 1.25vw 1.111vw;
                  padding: ${(props) =>
                    props.language == "en"
                      ? "1.25vw 2.222vw 1.25vw 1.111vw"
                      : "1.25vw 1.111vw  1.25vw 2.222vw "};

                  // height: 4.306vw;
                  background-size: 0.694vw 0.694vw;
                  font-size: 1.111vw;
                  line-height: 1.667vw;
                  background-position-x: ${(props) =>
                    props.language == "en" ? "91%" : "9%"};
                  @media (min-width: 1440px) {
                    font-size: 16px;
                    line-height: 24px;
                  }
                }
                input::placeholder {
                  font-weight: 500;
                  font-size: 0.972vw;
                  line-height: 1.458vw;
                  color: #9a9ea7;
                  opacity: 1;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  @media (min-width: 1440px) {
                    font-size: 14px;
                    line-height: 21px;
                  }
                }
                .mobile-input-div {
                  input {
                    padding: ${(props) =>
                      props.language == "en"
                        ? "1.25vw 1.111vw 1.25vw 8.681vw"
                        : "1.25vw  8.681vw  1.25vw 1.111vw"};
                  }
                  .select-code {
                    top: 1.389vw;
                    input {
                      width: 6.944vw;
                      height: 2.222vw;
                      font-family: Poppins;
                      background-size: 0.694vw 0.694vw;
                      background-position: ${(props) =>
                        props.language == "en" ? "98% 20%" : "2% 20%"};
                      padding: 0;
                    }
                  }
                }
              }

              .seller-input-div {
                width: 42.917vw;
                input[name="seller"] {
                  width: 42.917vw;
                  background-position: ${(props) =>
                    props.language == "en" ? " 96% 50%" : " 4% 50%"};
                }
              }
            }
            .btn-container {
              margin-top: 1.667vw;
              button {
                width: 42.917vw;
                height: 3.611vw;
                font-size: 1.25vw;
                line-height: 1.458vw;
                border-radius: 0.278vw;

                @media (min-width: 1440px) {
                  font-size: 18px;
                  line-height: 21px;
                }
              }
            }
          }
        }
      }
    }
    .detail-container {
      padding: 0 2.222vw 0 1.667vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .car-img {
        width: 28.056vw;
        height: 15.764vw;
        object-fit: cover;
        -webkit-transform: ${(props) =>
          props.language === "ar" && "scaleX(-1)"};
        transform: ${(props) => props.language === "ar" && "scaleX(-1)"};
      }
      .price-div {
        width: 28.056vw;
        height: 6.389vw;
        margin-top: 2.5vw;
        background: rgba(185, 185, 185, 0.1);
        border-radius: 0.556vw;
        padding: 1.111vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .text {
          font-family: ${(props) =>
            props.language == "en" ? "Poppins" : "GE-SS-Bold"};
          font-style: normal;
          font-weight: ${(props) => (props.language == "en" ? "600" : "700")};
          font-size: 1.944vw;
          line-height: 2.917vw;
          color: #0d0a19;

          @media (min-width: 1440px) {
            font-size: 28px;
            line-height: 42px;
          }
        }
        .value-div {
          .value {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 1.944vw;
            line-height: 2.917vw;
            color: #0d0a19;
            @media (min-width: 1440px) {
              font-size: 28px;
              line-height: 42px;
            }

            .currency {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 0.972vw;
              line-height: 1.458vw;

              @media (min-width: 1440px) {
                font-size: 14px;
                line-height: 21px;
              }
            }
          }
          .tax {
            font-family: ${(props) =>
              props.language == "en" ? "Poppins" : "GE-SS-UltraLight"};
            font-style: normal;
            font-weight: ${(props) => (props.language == "en" ? "400" : "300")};
            font-size: 0.833vw;
            line-height: 1.25vw;
            color: #0d0a19;
            opacity: 0.6;

            @media (min-width: 1440px) {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .container {
      padding: ${mobileSizes._8px} ${mobileSizes._16px} ${mobileSizes._24px};
      .mobile-stepper {
        .steps {
          gap: ${mobileSizes._6px};
          .step {
            width: 17.067vw;
          }
        }
      }

      .card {
        margin-top: 12vw;
        width: 91.467vw;
        background: #fbfbfb;
        border: none;
        display: flex;
        flex-direction: column;
        gap: 12vw;
        .forms {
          order: 2;
          padding: 0;
          width: 100%;
          padding: 0;
          border: 0;
          .section-title {
            font-size: ${mobileSizes._20px};
            line-height: ${mobileSizes._32px};
            margin-bottom: ${mobileSizes._16px};
            color: #0d0a19;
          }
          .form-container {
            width: 100%;
            form {
              .fields {
                display: flex;
                gap: ${mobileSizes._16px};
                .input-div {
                  width: 91.467vw;
              
                  input {
                    width: 100%;
                    height: auto;
                    padding: ${(props) =>
                      props.language == "en"
                        ? `${mobileSizes._16px} ${mobileSizes._32px}
                    ${mobileSizes._16px} ${mobileSizes._16px}`
                        : `${mobileSizes._16px} ${mobileSizes._16px}
                    ${mobileSizes._16px} ${mobileSizes._32px}`};

                    background-size: ${mobileSizes._10px} ${mobileSizes._10px};
                    font-weight: 500;
                    font-size: ${mobileSizes._16px};
                    line-height: ${mobileSizes._24px};
                    border-radius: ${mobileSizes._8px};
                    background-position: ${(props) =>
                      props.language == "en" ? "95% 50%" : "5% 50%"};
                  }
                  input::placeholder {
                    font-size: ${mobileSizes._14px};
                    line-height: ${mobileSizes._21px};
                  }
                  .mobile-input-div {
                    input {
                      padding: ${(props) =>
                        props.language == "en"
                          ? `${mobileSizes._18px} ${mobileSizes._32px}
                        ${mobileSizes._18px} 33.333vw`
                          : ` ${mobileSizes._18px} 33.333vw 
                        ${mobileSizes._18px} ${mobileSizes._32px}`};
                    }
                    .select-code {
                      top: ${mobileSizes._20px};
                      left: ${mobileSizes._16px};
                      width: 26.667vw;
                      input {
                        width: 26.667vw;
                        height: 5.867vw;
                        background-size: ${mobileSizes._10px}
                          ${mobileSizes._10px};
                        background-position: ${(props) =>
                          props.language == "en" ? "96% 50%" : "4% 50%"};
                        padding: 0;
                        border: none;
                      }
                    }
                  }
                }
                .seller-input-div {
                  width: 100%;
                  input[name="seller"] {
                    width: 100%;
                    background-position: ${(props) =>
                      props.language == "en" ? "95% 50%" : "5% 50%"};
                  }
                }
              }
              .btn-container {
                margin-top: ${mobileSizes._24px};
                button {
                  width: 100%;
                  height: 14.933vw;
                  font-size: ${mobileSizes._18px};
                  line-height: ${mobileSizes._27px};
                  border-radius: ${mobileSizes._4px};
                }
              }
            }
          }
        }
      }
      .detail-container {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .car-img {
          width: 71.2vw;
          height: 40vw;
          object-contain: fit;
        }
      }
    }
  }
`;
export default CarFinance;
