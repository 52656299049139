import React, { useState, useEffect } from "react";
import styled from "styled-components";
import n1 from "../images/news.png";
import n2 from "../images/news2.png";
import n3 from "../images/car.png";
import { desktopSizes, mobileSizes } from "../util/sizes";
import ItemCard from "../Components/Home/ItemCard";
import { useSelector } from "react-redux";
import en from "../util/locales/en";
import ar from "../util/locales/ar";
import mobileheader_bg from "../images/header-reviews.png";

const review = {
  title: "BMW 220d Coupé M Sport delivers thrills without thirst",
  text: "This two-door diesel has athletic looks and sporty driver appeal but saves money at the pumps, The sensible turbodiesel engine was somewhat out of kilter with the racy looks of this low-slung coupé as the car’s athletic shape seemed to call for high-revving petrol power. But that’s not to say the BMW 220d Coupe M Sport is deficient in the performance department. The four-cylinder 2.0 turbodiesel engine has been a stalwart in BMW’s range, powering se  engine has been a stalwart in BMW’s range, powering se",
  title_ar: "مراجعة: بي إم دبليو 220 دي كوبيه إم سبورت ",
  text_ar:
    "يتميز هذا الديزل ذو البابين بمظهر رياضي وجاذبية سائق رياضي ولكنه يوفر المال في المضخات كان المحرك التوربيني المعقول خارج حالة جيدة إلى حد ما مع المظهر المفعم بالحيوية لهذا الانقلاب المنخفض-حيث بدا أن الشكل الرياضي للسيارة يتطلب قوة بنزين عالية السرعة. لكن هذا لا يعني أن بي إم دبليو 220 دي كوبيه إم سبورت تعاني من نقص في قسم الأداء.  وكان محرك توربوديزل 2.0 أربع أسطوانات نصير في مجموعة بي أم دبليو ، المحرك سراج الدين .. إقرأ المزيد",
  image: n1,
};

const latest_reviews = [
  review,
  { ...review, image: n2 },
  { ...review, image: n3 },
  review,
  { ...review, image: n2 },
  { ...review, image: n3 },
];

const all_reviews = [
  review,
  { ...review, image: n2 },
  { ...review, image: n3 },
  review,
  { ...review, image: n2 },
  { ...review, image: n3 },
  review,
  { ...review, image: n2 },
  { ...review, image: n3 },
];
const CarReviews = () => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  return (
    <StyledPage language={language}>
      <div className="shape1 desktop-only"></div>
      <div className="shape2 desktop-only"></div>

      <div className="title">{t.carReviewsPage.title}</div>
      <div className="lists">
        <div className="reviews desktop-only">
          <p className="section-title">{t.carReviewsPage.latestReviews}</p>
          <div className="list">
            {latest_reviews.map((r, i) => (
              <ItemCard item={r} key={i} page={"carReviews"} />
            ))}
          </div>
        </div>

        <div className="reviews">
          <p className="section-title desktop-only">
            {t.carReviewsPage.allReviews}
          </p>
          <div className="list">
            {all_reviews.map((r, i) => (
              <ItemCard item={r} key={i} page={"carReviews"} />
            ))}
          </div>
        </div>
      </div>
    </StyledPage>
  );
};
const StyledPage = styled.div`
  overflow: hidden;
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  position: relative;
  padding: 3.611vw 10.694vw 5.556vw 10.417vw;
  .shape1 {
    position: absolute;
    z-index: 0;
    top: -30.347vw;
    left: -15.347vw;
    width: 130.625vw;
    height: 44.167vw;
    border-radius: 60%;
    border: ${desktopSizes._1px} solid #979797;
    opacity: 0.25;
  }
  .shape2 {
    position: absolute;
    z-index: 0;
    top: -30.347vw;
    left: -15.347vw;
    width: 130.625vw;
    height: 47.847vw;
    border-radius: 60%;
    border: ${desktopSizes._1px} solid #979797;
    opacity: 0.25;
  }
  .title {
    text-align: center;
    font-family: ${(props) =>
      props.language == "en" ? "Poppins" : "GE-SS-Bold"};
    font-size: 3.194vw;
    line-height: 4.792vw;
    font-style: normal;
    font-weight: ${(props) => (props.language == "en" ? "600" : "700")};
    margin-bottom: ${desktopSizes._32px};
    @media (min-width: 1440px) {
      font-size: 46px;
      line-height: 69px;
    }
  }
  .lists {
    display: flex;
    flex-direction: column;
    gap: 3.125vw;
    .reviews {
      z-index: 1000;
      .section-title {
        margin-bottom: ${desktopSizes._32px};
        color: #0d0a19;
        font-family: ${(props) =>
          props.language == "en" ? "Poppins" : "GE-SS-Bold"};
        font-size: ${desktopSizes._24px};
        line-height: ${desktopSizes._36px};
        font-style: normal;
        font-weight: ${(props) => (props.language == "en" ? "600" : "700")};
        @media (min-width: 1440px) {
          font-size: 24px;
          line-height: 36px;
        }
      }
      .list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: ${desktopSizes._28px};
      }
    }
  }

  @media (max-width: 768px) {
    padding: ${mobileSizes._6px} ${mobileSizes._16px} ${mobileSizes._24px};
    background-image: url(${mobileheader_bg});
    background-repeat: no-repeat;
    // background-size: contain;
    background-position: 0 -13.333vw;
    .title {
      font-size: ${mobileSizes._24px};
      line-height: ${mobileSizes._36px};
      margin-bottom: ${mobileSizes._24px};
    }
    .lists {
      display: flex;
      flex-direction: column;
      gap: ${mobileSizes._24px};
      .reviews {
        z-index: 1000;
        .section-title {
          margin-bottom: ${mobileSizes._24px};
          font-size: ${mobileSizes._20px};
          line-height: ${mobileSizes._30px};
        }
        .list {
          display: flex;
          flex-direction: column;
          gap: ${mobileSizes._15px};
        }
      }
    }
  }
`;
export default CarReviews;
