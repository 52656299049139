export const range = (size, startAt = 0) => {
    return [...Array(size).keys()].map((i) => i + startAt);
  };
  

  ////////////////////////////////////////////////
export const formatApiString = (string, delimiter = "_") => {
  let strArr = string.split("");
  return [strArr[0].toUpperCase(), ...strArr.splice(1)]
    .join("")
    .split(delimiter)
    .join(" ");
};

export const capitalizeString = (string, delimiter = "_") => {
  const resArr = [];
  const stringArr = string.split(delimiter);
  stringArr.map((part) => {
    resArr.push(formatApiString(part));
  });

  return resArr.join(" ");
};
////////////////////////////////////////////////
export const cleanObject = (obj) => {
  for (var propName in obj) {
    if (typeof obj[propName] === "object") cleanObject(obj[propName]);
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
};
