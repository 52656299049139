import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BuildStep from "../Components/BuildCar/BuildStep";
import car_img from "../images/build-car/car.png";
import engine from "../images/build-car/engine.png";
import carTrim from "../images/build-car/car-trim.png";
import gear from "../images/build-car/gear.png";
import payment from "../images/build-car/payment.png";
import { axiosCarvenui } from "../util/integration";
import { useSelector } from "react-redux";
import en from "../util/locales/en";
import ar from "../util/locales/ar";

const BuildCar = () => {
  const [step, setStep] = useState(1);
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedEngineCapacity, setSelectedEngineCapacity] = useState();
  const [selectedCarTrim, setSelectedCarTrim] = useState();
  const [selectedGearBox, setSelectedGearBox] = useState();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  useEffect(() => {
    const getBrands = async () => {
      try {
        setLoading(true);
        const res = await axiosCarvenui.get(`/brand?page_size=24`);
        console.log("res", res);
        const data = res.data.results;
        setBrands(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getBrands();
  }, []);

  // const []
  const steps = [
    {
      id: 1,
      item: t.buildCarPage.brand,
      itemValues: brands,
      selected: selectedBrand,
      selectValue: setSelectedBrand,
      image: car_img,
    },
    {
      id: 2,
      item: t.buildCarPage.engineCapacity,
      itemValues: [
        { name: "1000 CC", value: 1000 },
        { name: "1600 CC", value: 1600 },
        { name: "2000 CC", value: 2000 },
        { name: "3000 CC", value: 3000 },
      ],
      selectValue: setSelectedEngineCapacity,
      selected: selectedEngineCapacity,
      image: engine,
    },
    {
      id: 3,
      item: t.buildCarPage.carTrim,
      itemValues: [
        { name: "Performance Line" },
        { name: "Performance Line +" },
        { name: "Rivoli" },
        { name: "Opera" },
        { name: "Touring edition" },
        { name: "Grand Touring" },
        { name: "Extra" },
        { name: "Classic/Custom Edition" },
        { name: "Sport Edition" },
        { name: "Special Edition" },
      ],
      selectValue: setSelectedCarTrim,
      selected: selectedCarTrim,
      image: carTrim,
    },
    {
      id: 4,
      item: t.buildCarPage.gearBox,
      itemValues: [
        { name: "Manual" },
        { name: "Automatic" },
        { name: "Hybrid" },
      ],
      selectValue: setSelectedGearBox,
      selected: selectedGearBox,
      image: gear,
    },
    {
      id: 5,
      item: t.buildCarPage.paymentMethod,
      itemValues: [
        { name: "Cash" },
        { name: "Leasing" },
        { name: "Instalments" },
      ],
      selectValue: setSelectedPaymentMethod,
      selected: selectedPaymentMethod,
      image: payment,
    },
  ];
  return (
    !loading && (
      <StyledPage>
        <BuildStep
          key={step}
          step={step}
          setStep={setStep}
          item={steps[step - 1].item}
          itemValues={steps[step - 1].itemValues}
          image={steps[step - 1].image}
          selectValue={steps[step - 1].selectValue}
          selected={steps[step - 1].selected}
        />
      </StyledPage>
    )
  );
};
const StyledPage = styled.div``;
export default BuildCar;
