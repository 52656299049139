import React, { useEffect, useState } from "react";
import StyledForm from "../assets/StyledForm";
import SelectMenu from "../assets/SelectMenu";
import { countriesCodes } from "../../util/data";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import { useForm } from "react-hook-form";

const Form = ({
  onSubmit,
  formData,
  setFormData,
  fields,
  submitText = "Submit",
  ...props
}) => {
  const codeField = {
    name: "code",
    options: countriesCodes,
  };
  const checkIsMobile = () => window.innerWidth <= 768;
  const [isMobile, setIsMobile] = useState(checkIsMobile());
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  useEffect(() => {
    const onResize = () => {
      setIsMobile(checkIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [window.innerWidth]);

  function isArabic(text) {
    var pattern = /[\u0600-\u06FF\u0750-\u077F]/;
    let result = pattern.test(text);
    return result;
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm();

  const formSubmit = handleSubmit((data) => {
    console.log("form data", data);
    console.log("errors", errors);
    onSubmit();
  });

  useEffect(() => {
    console.log("errors", errors);
  }, [errors]);

  useEffect(() => {
    if (formData.seller) {
      setError("seller", null);
    }
  }, [formData]);

  return (
    <StyledForm
      onSubmit={(e) => {
        e.preventDefault();
        // onSubmit();
      }}
    >
      <div className="fields">
        {fields.map((field) =>
          field.type === "select" && field.name != "code" ? (
            <div className={`input-div ${field.name}-input-div`}>
              {
                <p>
                  {field.displayName}
                  {field?.required !== false && (
                    <span
                      style={{
                        color: "#EB5757",
                      }}
                    >
                      *
                    </span>
                  )}
                </p>
              }
              <div className="select-menu">
                <SelectMenu
                  field={field}
                  disabled={props.disabled || field.disabled}
                  value={formData[field.name]}
                  setValue={(value) => {
                    setFormData({
                      ...formData,
                      [field.name]: value,
                    });
                    setError(field.name, null);
                  }}
                  getPaginationData={field.getPaginationData}
                  registerObj={{
                    ...register(field.name, {
                      required: {
                        value:
                          field.required === false
                            ? false
                            : formData[field.name]
                            ? false
                            : true,
                        message: "required",
                      },
                    }),
                  }}
                  search={field.search || null}
                  allowTyping={field.allowTyping || false}
                />
                {errors[field.name]?.message && (
                  <p className="error-text">
                    {language == "en"
                      ? `${field.displayName} is ${
                          t[errors[field.name]?.message]
                        }`
                      : `${t[errors[field.name]?.message]} ${
                          field.displayName
                        }`}
                  </p>
                )}
              </div>
            </div>
          ) : field.name == "phone" ? (
            <div className="input-div" key="mobile">
              <p>
                {t.mobileNumber}
                {field?.required !== false && (
                  <span
                    style={{
                      color: "#EB5757",
                    }}
                  >
                    *
                  </span>
                )}
              </p>
              <div className="mobile-input-div">
                <input
                  name="mobile"
                  placeholder={t.authPages.mobile}
                  // type={"number"}
                  type="text"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  required={true}
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                  {...register("mobile", {
                    required: {
                      value: true,
                      message: `required`,
                    },
                    validate: (value) => {
                      if (!isNaN(value) || formData["mobile"]) {
                        return true;
                      } else return `required`;
                    },
                    valueAsNumber: true,

                    onChange: (e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      setFormData({
                        ...formData,
                        mobile: value,
                      });
                    },
                  })}
                  value={formData["mobile"] || ""}
                />
                <div className="select-code">
                  <SelectMenu
                    field={codeField}
                    value={formData.countryCode}
                    setValue={(value) => {
                      setFormData({
                        ...formData,
                        countryCode: value,
                      });
                    }}
                  />
                </div>
              </div>
              {errors["mobile"]?.message && (
                <p className="error-text">
                  {language == "en"
                    ? `${t.carFinance.personalInfo.mobileNumber} is ${
                        t[errors["mobile"]?.message]
                      }`
                    : `${t[errors["mobile"]?.message]} ${
                        t.carFinance.personalInfo.mobileNumber
                      } `}
                </p>
              )}
            </div>
          ) : (
            <div className="input-div" key={field.name}>
              <p>
                {field.displayName}{" "}
                {field?.required !== false && (
                  <span
                    style={{
                      color: "#EB5757",
                    }}
                  >
                    *
                  </span>
                )}
              </p>
              {field.displayCurrency && <p className="currency">SAR</p>}
              <input
                name={field.name}
                placeholder={field.placeholder}
                type={"text"}
                inputmode={field.type == "number" && "numeric"}
                pattern={field.type == "number" && "[0-9]*"}
                required={field.required === false ? false : true}
                onKeyDown={(e) => {
                  if (field.type == "number") {
                    if (e.key === "e" || e.key === "-") {
                      e.preventDefault();
                    }
                  }
                }}
                {...register(field.name, {
                  required: {
                    value: field.required === false ? false : true,
                    message: `required`,
                  },
                  validate: (value) => {
                    if (field.required !== false && field.type == "number") {
                      if (!isNaN(value) || formData[field.name]) {
                        return true;
                      } else return `required`;
                    }
                  },
                  valueAsNumber: field.type == "number",
                  pattern:
                    field.name == "email"
                      ? {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: `invalid`,
                        }
                      : {},
                  onChange: (e) => {
                    const value =
                      field.type == "number"
                        ? e.target.value.replace(/\D/g, "")
                        : e.target.value;
                    setFormData({
                      ...formData,
                      [field.name]: value,
                    });
                  },
                })}
                style={
                  field.displayCurrency
                    ? language == "en"
                      ? {
                          paddingRight: !isMobile ? "3.819vw" : "13.333vw",
                        }
                      : {
                          paddingLeft: !isMobile ? "3.819vw" : "13.333vw",
                        }
                    : {}
                }
                value={formData[field.name] || ""}
                className={`${
                  isArabic(formData[field.name]) ? "arabic-input" : ""
                } ${!isNaN(+field.placeholder) ? "number-placeholder" : ""}`}
              />
              {errors[field.name]?.message && (
                <p className="error-text">
                  {errors[field.name]?.message != "invalid"
                    ? language == "en"
                      ? `${field.displayName} is ${
                          t[errors[field.name]?.message]
                        }`
                      : `${t[errors[field.name]?.message]} ${field.displayName}`
                    : language == "en"
                    ? `${t[errors[field.name]?.message]} ${field.displayName}`
                    : ` ${field.displayName} ${t[errors[field.name]?.message]}`}
                </p>
              )}
            </div>
          )
        )}
      </div>

      <div className="btn-container">
        <button className="submit" type="submit" onClick={formSubmit}>
          {submitText}
        </button>
      </div>
    </StyledForm>
  );
};

export default Form;
