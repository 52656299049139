import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PrevNextPagination from "../assets/PrevNextPagination";
import Review from "./Review";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import car_img from "../../images/car/img1.png";
import { mobileSizes } from "../../util/sizes";


const review = {
  title: "BMW 220d Coupé M Sport delivers thrills without thirst",
  title_ar: " بي إم دبليو 220 دي كوبيه إم سبورت تقدم الإثارة دون عطش",
  text: "This two-door diesel has athletic looks and sporty driver appeal but saves money at the pumps  \n\n The sensible turbodiesel engine was somewhat out of kilter with the racy looks of this low-slung coupé as the car’s athletic shape seemed to call for high-revving petrol power. But that’s not to say the BMW 220d Coupe M Sport is deficient in the performance department. \n\n The four-cylinder 2.0 turbodiesel engine has been a stalwart in BMW’s range, powering se  engine has been a stalwart in BMW’s range, powering se",
  text_ar:
    " يتميز هذا الديزل ذو البابين بمظهر رياضي وجاذبية سائق رياضي ولكنه يوفر المال في المضخات\n \n  كان المحرك التوربيني المعقول خارج حالة جيدة إلى حد ما مع المظهر المفعم بالحيوية لهذا الانقلاب المنخفض-حيث بدا أن الشكل الرياضي للسيارة يتطلب قوة بنزين عالية السرعة. لكن هذا لا يعني أن بي إم دبليو 220 دي كوبيه إم سبورت تعاني من نقص في قسم الأداء.  \n \nوكان محرك توربوديزل 2.0 أربع أسطوانات نصير في مجموعة بي أم دبليو يتميز هذا الديزل ذو البابين بمظهر رياضي وجاذبية سائق رياضي ولكنه يوفر المال في المضخات  كان المحرك التوربيني المعقول خارج حالة جيدة إلى حد ما مع المظهر المفعم بالحيوية لهذا الانقلاب المنخفض-حيث بدا أن الشكل الرياضي للسيارة يتطلب قوة بنزين عالية السرعة. لكن هذا لا يعني أن بي إم دبليو 220 دي كوبيه إم سبورت تعاني من نقص في قسم الأداء.  وكان محرك توربوديزل 2.0 أربع أسطوانات نصير في مجموعة بي أم دبليو ، المحرك سراج الدين    ",
  image: car_img,
};
const Reviews = () => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  return (
    <StyledSection language={language}>
      <div className="header">
        <div className="section-header">{t.reviews.carvinuReviews}</div>
        <div className="desktop-only">
          <PrevNextPagination />
        </div>
      </div>
      <div className="reviews">
        <Review review={review} />
        <img src={review.image} />
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  padding: ${(props) =>
    props.language == "en" ? " 0 10.417vw" : "0 10.694vw "};
  margin-top: 5.208vw;
  margin-bottom: 5.556vw;
  padding: 0 10.417vw;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .reviews {
    margin-top: 2.917vw;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    img {
      width: 38.542vw;
      height: 22.5vw;
      object-fit: cover;
      -webkit-transform: ${(props) => props.language === "ar" && "scaleX(-1)"};
      transform: ${(props) => props.language === "ar" && "scaleX(-1)"};
    }
    // width: 31.736vw;
  }

  @media (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 6.4vw;
    padding: ${(props) =>
      props.language == "en" ? "0 0 0 4.267vw" : "0 4.267vw 0 0"};
    .header {
      margin-right: 0;
    }
    .reviews {
      margin-top: 4.267vw;
      display: flex;
      flex-direction: column-reverse;
      gap: ${mobileSizes._8px};
      img {
        width: 91.467vw;
        height: 41.333vw;
        object-fit: cover;
      }
    }
  }
`;
export default Reviews;
