import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import { desktopSizes, mobileSizes } from "../../util/sizes";

// News Or Review Card

const ItemCard = ({ item, ...props }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  return (
    <StyledCard language={language} {...props}>
      <img className="item-img" src={item.image} />
      <div
        className={`item-title ${
          props.page == "carReviews" ? "title-reviews-styles" : "title-styles"
        }`}
      >
        {language == "en" ? item.title : item.title_ar}
      </div>
      <div className="item-text">
        {language == "en" ? item.text : item.text_ar}
      </div>
    </StyledCard>
  );
};

const StyledCard = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  width: 25vw;
  .item-img {
    width: 25vw;
    height: 15.694vw;
    margin-bottom: 1.111vw;
    object-fit: cover;
    border-radius: ${desktopSizes._8px} ${desktopSizes._8px} 0px 0px;
  }
  .item-title {
    font-family: ${(props) =>
      props.language == "en" ? "Poppins" : "GE-SS-Bold"};
    font-style: normal;
    font-weight: 600;
    font-size: 1.111vw;
    line-height: 1.667vw;
    width: 25vw;
    height: 1.667vw;
    color: #0d0a19;
    text-overflow: ellipsis;
    white-space: nowrap; /* keeps the text in a single line */
    overflow: hidden;
    margin-bottom: 0.278vw;

    @media (min-width: 1440px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .item-text {
    font-family: ${(props) =>
      props.language == "en" ? "Poppins" : "GE-SS-UltraLight"};
    font-style: normal;
    font-weight: ${(props) => (props.language == "en" ? "400" : "300")};
    font-size: 0.972vw;
    line-height: 1.458vw;
    color: #5a5a5a;
    height: 5.833vw;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    white-space: pre-wrap;

    @media (min-width: 1440px) {
      font-size: 14px;
      line-height: 21px;
    }
  }

  @media (max-width: 768px) {
    width: ${(props) => (props.page == "carReviews" ? "91.467vw" : "63.467vw")};
    height: ${(props) => (props.page == "carReviews" ? "auto" : "97.867vw")};
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: ${(props) =>
      props.page == "carReviews"
        ? "0"
        : props.language == "en"
        ? "1.067vw 1.067vw 2.133vw rgba(0, 0, 0, 0.12)"
        : "-1.067vw 1.067vw 2.133vw rgba(0, 0, 0, 0.12)"};
    border-radius: 2.133vw;
    background: ${(props) => (props.page == "carReviews" ? "none" : "#ffffff")};
    .item-img {
      width: ${(props) =>
        props.page == "carReviews" ? "91.467vw" : "63.467vw"};
      height: ${(props) =>
        props.page == "carReviews" ? "60.267vw" : " 37.333vw"};
      margin-bottom: ${mobileSizes._16px};
      border-radius: 2.133vw 2.133vw 0px 0px;
    }
    .item-title {
      font-size: 4.267vw;
      line-height: 6.4vw;
      width: ${(props) =>
        props.page == "carReviews" ? "91.467vw" : "54.933vw"};
      height: ${(props) =>
        props.page == "carReviews" ? mobileSizes._24px : "12.8vw"};
      margin-bottom: ${mobileSizes._8px};
    }
    .title-styles {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: pre-wrap;
      margin-bottom: ${mobileSizes._4px};
    }
    .title-reviews-styles {
      margin-bottom: ${mobileSizes._4px};
    }
    .item-text {
      font-size: 3.733vw;
      line-height: ${mobileSizes._21px};
      width: ${(props) =>
        props.page == "carReviews" ? "91.467vw" : "54.933vw"};
      height: ${(props) => (props.page == "carReviews" ? "22.4vw" : "39.2vw")};
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${(props) =>
        props.page == "carReviews" ? "4" : "7"};
      white-space: pre-wrap;
    }
  }
`;

export default ItemCard;
