import "./App.css";
import Car from "./pages/Car";
import GlobalStyle from "./util/globalStyles";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Cars from "./pages/Cars";
import BuildCar from "./pages/BuildCar";
import BlankContainer from "./BlankContainer";
import DefaultContainer from "./DefaultContainer";
import ScrollTop from "./Components/assets/ScrollTop";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import CarFinance from "./pages/CarFinance";
import { useEffect, useState } from "react";
import CarReviews from "./pages/CarReviews";

function App() {
  const checkIsMobile = () => window.innerWidth <= 768;
  const [isMobile, setIsMobile] = useState(checkIsMobile());
  useEffect(() => {
    const onResize = () => {
      setIsMobile(checkIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [window.innerWidth]);
  return (
    <div className="App">
      <ScrollTop />
      <GlobalStyle />
      <Routes>
        <Route element={<DefaultContainer />}>
          <Route path="/" element={<Home />} />
          <Route path="/car/:id" element={<Car />} />
          <Route path="/cars" element={<Cars />} />
          <Route path={"car-finance"} element={<CarFinance />} />
          <Route path={"reviews"} element={<CarReviews />} />
          {isMobile && <Route path="/build-car" element={<BuildCar />} />}
          {isMobile && <Route path="login" element={<Login />} />}
          {isMobile && <Route path="sign-up" element={<Signup />} />}
          {isMobile && (
            <Route path="forget-password" element={<ForgetPassword />} />
          )}
        </Route>
        {!isMobile && (
          <Route element={<BlankContainer />}>
            <Route path="/build-car" element={<BuildCar />} />
            <Route path="sign-up" element={<Signup />} />
            <Route path="login" element={<Login />} />
            <Route path="forget-password" element={<ForgetPassword />} />
          </Route>
        )}
        <Route path="*" element={<DefaultContainer />} />
      </Routes>
    </div>
  );
}

export default App;
