import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { desktopSizes, mobileSizes } from "../../util/sizes";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const PlanCard = ({ bulk_payment_detail, base_details, ...props }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const planAttr = {
    down_payment: {
      key: t.carFinance.result.downPayment,
      value: (+base_details.down_payment.toFixed(2))
        .toLocaleString()
        .replace(/\.00$/, ""),
    },
    loan_amount: {
      key: t.carFinance.result.loanAmount,
      value: parseFloat(
        (
          base_details.admin_fees_with_vat +
          bulk_payment_detail.profit_amount +
          bulk_payment_detail.bulk_payment_amount +
          bulk_payment_detail.insurance_amount
        ).toFixed(2)
      )
        .toLocaleString()
        .replace(/\.00$/, ""),
    },
    admin_fees: {
      key: t.carFinance.result.adminFees,
      value: parseFloat(base_details.admin_fees_with_vat.toFixed(2))
        .toLocaleString()
        .replace(/\.00$/, ""),
    },
    tenure: {
      key: t.carFinance.result.tenure,
      value: `${bulk_payment_detail.years}  ${t.years}`,
    },
    last_batch: {
      key: t.carFinance.result.lastBatch,
      value:
        `${(bulk_payment_detail.bulk_payment_percentage * 100)
          .toFixed(2)
          .replace(/\.00$/, "")}%` +
        " - " +
        (+bulk_payment_detail.bulk_payment_amount.toFixed(2))
          .toLocaleString()
          .replace(/\.00$/, ""),
    },
  };
  return (
    <StyleCard language={language}>
      <div className="card-header">
        {language == "en"
          ? `${bulk_payment_detail.years} ${t.carFinance.result.year} ${t.carFinance.result.installment}`
          : `${t.carFinance.result.installment}  ${bulk_payment_detail.years} ${t.carFinance.result.year}`}
      </div>
      <div className="recommended">{t.recommended}</div>
      <div className="items">
        {Object.keys(planAttr).map(
          (item) =>
            planAttr[item] && (
              <div className="item">
                <p className="key">{planAttr[item].key}</p>
                <p className="value">
                  {planAttr[item].value}{" "}
                  {item != "tenure" ? <span>SAR</span> : ""}
                </p>
              </div>
            )
        )}
      </div>
      <button className="book">
        <p className="book-text">{t.carFinance.result.bookNow}</p>
        <div className="installment">
          <p className="text">{t.carFinance.result.MonthlyInstallment}</p>
          <p className="value">
            {(+bulk_payment_detail.installment_amount.toFixed(2))
              .toLocaleString()
              .replace(/\.00$/, "")}{" "}
            <span>SAR</span>
          </p>
        </div>
      </button>
    </StyleCard>
  );
};

const StyleCard = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  width: 25.069vw;
  height: 28.542vw;
  background: #ffffff;
  border: 0.069vw solid #e4e6e8;
  border-radius: 0.278vw;
  padding: 1.667vw;
  position: relative;
  .recommended {
    position: absolute;
    top: 1.667vw;
    right: ${(props) => props.language == "en" && "1.111vw"};
    left: ${(props) => props.language == "ar" && "1.111vw"};
    // width: 7.222vw;
    // height: 2.083vw;
    background: rgba(52, 168, 83, 0.1);
    border-radius: 0.208vw;
    padding: 0.417vw;
    font-family: ${(props) =>
      props.language == "en" ? "Poppins" : "GE-SS-Meduim"};
    font-style: normal;
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 1.25vw;

    /* identical to box height */

    color: #34a853;
    @media(min-width : 1440px){
      font-size: 12px;
      line-height: 18px;
    }
  }
  .card-header {
    margin-bottom: 2.222vw;
    font-family: ${(props) =>
      props.language == "en" ? "Poppins" : "GE-SS-Bold"};
    font-style: normal;
    font-weight: ${(props) => (props.language == "en" ? "600" : "700")};
    font-size: 1.389vw;
    line-height: 2.083vw;
    color: #0d0a19;
    @media(min-width : 1440px){
      font-size: 20px;
      line-height: 30px;
    }
  }
  .items {
    display: grid;
    grid-template-columns: 65% 35%;
    align-items: center;
    justify-content: space-between;
    row-gap: 1.667vw;

    .item {
      display: flex;
      flex-direction: column;
      gap: 0.556vw;

      .key {
        font-family: ${(props) =>
          props.language == "en" ? "Poppins" : "GE-SS-UltraLight"};
        font-style: normal;
        font-weight: ${(props) => (props.language == "en" ? "400" : "300")};
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: #828282;
        @media(min-width : 1440px){
          font-size: 14px;
          line-height: 21px;
        }
      }
      .value {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.667vw;
        color: #0d0a19;
        @media(min-width : 1440px){
          font-size: 16px;
          line-height: 24px;
        }
        span {
          font-size: ${desktopSizes._12px};
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          line-height: 1.667vw;
          color: #0d0a19;
          @media(min-width : 1440px){
            font-size: 12px;
            line-height: 24px;
          }
        }
      }
    }
  }
  .book {
    cursor: pointer;
    width: 21.736vw;
    height: 4.306vw;
    background: #154cd6;
    border-radius: 0.278vw;
    padding: 0 1.111vw;
    justify-content: space-between;
    margin-top: 2.222vw;
    .book-text {
      font-family: ${(props) =>
        props.language == "en" ? "Poppins" : "GE-SS-Bold"};
      font-style: normal;
      font-weight: ${(props) => (props.language == "en" ? "60" : "700")};
      font-size: 1.389vw;
      line-height: 2.083vw;
      color: #ffffff;
      @media(min-width : 1440px){
        font-size: 20px;
        line-height: 30px;
      }
    }
    .installment {
      .text {
        font-family: ${(props) =>
          props.language == "en" ? "Poppins" : "GE-SS-UltraLight"};
        font-style: normal;
        font-weight: ${(props) => (props.language == "en" ? "400" : "300")};
        font-size: 0.833vw;
        line-height: 1.25vw;
        color: #ffffff;
        @media(min-width : 1440px){
          font-size: 12px;
          line-height: 18px;
        }
      }
      .value {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 1.25vw;
        line-height: 1.875vw;
        color: #ffffff;
        @media(min-width : 1440px){
          font-size: 18px;
          line-height: 27px;
        }
        span {
          font-weight: 400;
          font-size: 0.833vw;
          line-height: 1.25vw;
          @media(min-width : 1440px){
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    width: 91.467vw;
    height: 92.8vw;
    background: #ffffff;
    border: ${mobileSizes._1px} solid #e4e6e8;
    border-radius: ${mobileSizes._4px};
    padding: ${mobileSizes._16px};
    position: relative;
    .recommended {
      position: absolute;
      top: ${mobileSizes._16px};
      right: ${(props) => props.language == "en" && mobileSizes._16px};
      left: ${(props) => props.language == "ar" && mobileSizes._16px};
      // width: 23.733vw;
      // height: 5.067vw;
      background: rgba(52, 168, 83, 0.1);
      border-radius: ${mobileSizes._3px};
      font-size: ${mobileSizes._10px};
      line-height: ${mobileSizes._15px};
      padding: ${mobileSizes._6px};
      text-align: center;
    }
    .card-header {
      margin-bottom: ${mobileSizes._16px};
      font-size: ${mobileSizes._16px};
      line-height: ${mobileSizes._24px};
    }
    .items {
      row-gap: ${mobileSizes._16px};

      .item {
        gap: ${mobileSizes._8px};

        .key {
          font-size: ${mobileSizes._14px};
          line-height: ${mobileSizes._21px};
        }
        .value {
          font-size: ${mobileSizes._16px};
          line-height: ${mobileSizes._24px};
          span {
            font-size: ${mobileSizes._12px};
            line-height: ${mobileSizes._24px};
          }
        }
      }
    }
    .book {
      width: 82.933vw;
      height: 16.533vw;
      border-radius: ${mobileSizes._4px};
      padding: 0 ${mobileSizes._16px};
      margin-top: ${mobileSizes._24px};
      .book-text {
        font-size: ${mobileSizes._20px};
        line-height: ${mobileSizes._30px};
      }
      .installment {
        .text {
          font-size: ${mobileSizes._12px};
          line-height: ${mobileSizes._18px};
        }
        .value {
          font-size: ${mobileSizes._18px};
          line-height: ${mobileSizes._27px};

          span {
            font-size: ${mobileSizes._12px};
            line-height: ${mobileSizes._18px};
          }
        }
      }
    }
  }
`;

export default PlanCard;
