import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as MinusCircle } from "../../images/minus-cirlce.svg";
import { ReactComponent as PlusCircle } from "../../images/add-circle.svg";
import PlanCard from "./PlanCard";
import bank_logo from "../../images/bank-logo.png";
import { mobileSizes } from "../../util/sizes";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const Offer = ({ offer, bank, index, ...props }) => {
  const [expand, setExpand] = useState(index == 0 ? true : false);
  const [offerData, setOfferData] = useState(offer);
  const [loading, setLoading] = useState(true);
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  useEffect(() => {
    if (offer) {
      setOfferData({
        ...offer,
        bulk_payment_details: Object.values(offer.bulk_payment_details),
      });
    }
  }, [offer]);

  useEffect(() => {
    setLoading(false);
  }, [offerData]);

  return (
    !loading && (
      <StyleSection language={language}>
        <div className="header">
          <div className="col">
            <button
              className="collapse-expand"
              onClick={() => {
                setExpand(!expand);
              }}
            >
              {expand ? <MinusCircle /> : <PlusCircle />}
            </button>
            <div className="bank-info desktop-only">
              <img className="bank-logo" src={bank.logo} />
              <p className="bank-name">{bank.name}</p>
              <p className="plans-count">
                &#40;{offerData?.bulk_payment_details.length}{" "}
                {t.carFinance.result.plans}&#41;
              </p>
            </div>

            <div className="bank-info-mobile mobile-only">
              <img className="bank-logo" src={bank.logo} />
              <div className="name-container">
                <p className="bank-name">{bank.name}</p>
                <div className="plans-recommmended">
                  <p className="plans-count">
                    &#40;{offerData?.bulk_payment_details.length}{" "}
                    {t.carFinance.result.plans} &#41;
                  </p>
                  <div className="recommended-label">{t.recommended}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="recommended-label desktop-only">{t.recommended}</div>
        </div>
        {expand && (
          <div className="plans">
            {offerData.bulk_payment_details.map((detail, index) => (
              <PlanCard
                bulk_payment_detail={detail}
                base_details={offerData.base_details}
                index={index}
              />
            ))}
          </div>
        )}
      </StyleSection>
    )
  );
};

const StyleSection = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.389vw;
    width: 51.944vw;
    .col {
      display: flex;
      align-items: center;
      gap: 1.398vw;
      .collapse-expand {
        cursor: pointer;
        border: none;
        outline: none;
        background: none;
        svg {
          width: 1.667vw;
          height: 1.667vw;
        }
      }

      .bank-info {
        display: flex;
        align-items: center;
        gap: 0.833vw;
        .bank-logo {
          width: 6.458vw;
          height: 2.222vw;
          object-fit: contain;
        }
        .bank-name {
          order: ${(props) => props.language == "ar" && "3"};
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 1.389vw;
          line-height: 2.083vw;
          color: #0d0a19;
          @media(min-width : 1440px){
            font-size: 20px;
            line-height: 30px;
          }
        }
        .plans-count {
          order: ${(props) => props.language == "ar" && "2"};
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 1.111vw;
          line-height: 2.083vw;
          color: #0d0a1999;
          @media(min-width : 1440px){
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
    }
    .recommended-label {
      width: 7.222vw;
      height: 2.083vw;
      background: rgba(52, 168, 83, 0.1);
      border-radius: 0.208vw;
      font-family: ${(props) =>
        props.language == "en" ? "Poppins" : "GE-SS-Meduim"};
      font-style: normal;
      font-weight: 500;
      font-size: 0.833vw;
      line-height: 1.25vw;
      color: #34a853;
      padding: 0.417vw;
      text-align: center;
      @media(min-width : 1440px){
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .plans {
    width: 51.944vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1.806vw;
    column-gap: 1.667vw;
  }

  @media (max-width: 768px) {
    .header {
      margin-bottom: ${mobileSizes._16px};
      width: auto;
      .col {
        display: flex;
        align-items: center;
        gap: ${mobileSizes._12px};
        .collapse-expand {
          svg {
            width: ${mobileSizes._20px};
            height: ${mobileSizes._20px};
          }
        }

        .bank-info-mobile {
          display: flex;
          align-items: center;

          gap: ${mobileSizes._8px};
          .bank-logo {
            width: 24.8vw;
            height: 8.267vw;
          }
          .name-container {
            .bank-name {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: ${mobileSizes._16px};
              line-height: ${mobileSizes._24px};
              color: #0d0a19;
            }
            .plans-recommmended {
              display: flex;
              align-items: center;
              gap: ${mobileSizes._4px};
              .plans-count {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: ${mobileSizes._12px};
                line-height: ${mobileSizes._18px};
                color: rgba(13, 10, 25, 0.6);
              }
              .recommended-label {
                width: 23.733vw;
                height: 5.067vw;
                background: rgba(52, 168, 83, 0.1);
                border-radius: ${mobileSizes._3px};
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: ${mobileSizes._10px};
                line-height: ${mobileSizes._15px};
                color: #34a853;
                padding: ${mobileSizes._2px} ${mobileSizes._6px};
                text-align: center;
              }
            }
          }
        }
      }
    }

    .plans {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: ${mobileSizes._16px};
    }
  }
`;

export default Offer;
