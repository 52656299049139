import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { ReactComponent as Compare } from "../../images/compare.svg";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const CarCard = ({ car_detail, ...props }) => {
  const [nameOverflow, setNameOverflow] = useState();
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const nameRef = useRef();

  useEffect(() => {
    if (nameRef.current?.scrollWidth > nameRef.current?.clientWidth) {
      setNameOverflow(true);
    }
  }, [nameRef.current]);
  return (
    <StyledCard language={language}>
      <img
        src={Object.values(car_detail.car.meta_data.images)[0]}
        className="car-img"
      />
      <div
        className="name"
        ref={nameRef}
        style={nameOverflow && language == "ar" ? { direction: "ltr" } : {}}
      >
        {car_detail.car.name}
      </div>
      <div className="price">
        <p className="text">{t.carCard.price}</p>
        <div className="value">
          <p>
            {(+(+car_detail.car_price).toFixed(2))
              .toLocaleString()
              .replace(/\.00$/, "")}
          </p>
          <p className="currency">SAR</p>
        </div>
      </div>
      <div className="row">
        <div className="logo-and-seller">
          <div className="logo-conatiner">
            <img src={car_detail.car.model.brand.logo} />
          </div>
          <div className="seller">
            <p className="seller-name">
              {language == "en"
                ? car_detail.seller.name
                : car_detail.seller.name_ar}
            </p>
            <p className="text">{t.carCard.seller}</p>
          </div>
        </div>
        {props.homePage && (
          <button className="compare">
            <Compare />
          </button>
        )}
      </div>
    </StyledCard>
  );
};

const StyledCard = styled.div`
  background: #ffffff;
  border: 0.069vw solid #e4e6e8;
  border-radius: 0.972vw;
  width: 25vw;
  height: 26.042vw;
  padding: 1.667vw;
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  .car-img {
    width: 21.667vw;
    height: 12.361vw;
    object-fit: cover;
    border-radius: 0.556vw;
    margin-bottom: 1.111vw;
  }
  .name {
    // height: 3.819vw;
    font-weight: 700;
    font-size: 1.25vw;
    line-height: 1.875vw;
    color: #0d0a19;
    margin-bottom: 0.833vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
    @media (min-width: 1440px) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  .price {
    margin-bottom: 0.833vw;
    .text {
      font-family: ${(props) =>
        props.language == "en" ? "Inter" : "GE-SS-UltraLight"};
      font-style: normal;
      font-weight: ${(props) => (props.language == "en" ? "400" : "300")};
      font-size: 0.833vw;
      line-height: 1.25vw;
      color: #9a9ea7;
      margin-bottom: 0.208vw;
      @media (min-width: 1440px) {
        font-size: 12px;
        line-height: 18px;
      }
    }
    .value {
      display: flex;
      align-items: flex-end;
      justify-content: ${(props) => props.language == "ar" && "start"};
      flex-direction: ${(props) => props.language == "ar" && "row-reverse"};
      gap: 0.278vw;
      font-weight: 700;
      font-size: 0.972vw;
      line-height: 1.458vw;
      color: #0d0a19;

      @media (min-width: 1440px) {
        font-size: 14px;
        line-height: 21px;
      }

      .currency {
        font-weight: 600;
        font-size: 0.694vw;
        // line-height: 0.972vw;
        @media (min-width: 1440px) {
          font-size: 10px;
        }
      }
    }
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .compare {
      background: none;
      border: none;
      cursor: pointer;
      svg {
        width: 1.458vw;
        height: 1.042vw;
      }
    }
    .logo-and-seller {
      display: flex;
      align-items: center;
      gap: 0.833vw;
      .logo-conatiner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 2.639vw;
        height: 2.639vw;
        border: 0.069vw solid #e4e6e8;
        border-radius: 0.556vw;

        @media (min-width: 1440px) {
          width: 38px;
          height: 38px;
        }
      }
      img {
        width: 2.083vw;
        object-fit: contain;
        @media (min-width: 1440px) {
          width: 30px;
          
        }
      }
    }
    .seller {
      
      .seller-name {
        font-family: ${(props) =>
          props.language == "en" ? "Inter" : "GE-SS-Bold"};
        width: 19.861vw;
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: #0d0a19;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
      .text {
        font-family: ${(props) =>
          props.language == "en" ? "Inter" : "GE-SS-UltraLight"};
        font-style: normal;
        font-weight: ${(props) => (props.language == "en" ? "400" : "300")};

        font-size: 0.833vw;
        line-height: 1.25vw;
        color: #9a9ea7;

        @media (min-width: 1440px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    border: none;
    border-radius: 2.133vw;
    width: 63.467vw;
    height: 79.733vw;
    padding: 4.267vw;
    box-shadow: ${(props) =>
      props.language == "en"
        ? "1.067vw 1.067vw 2.133vw rgba(0, 0, 0, 0.12)"
        : "-1.067vw  1.067vw 2.133vw rgba(0, 0, 0, 0.12)"};

    .car-img {
      width: 54.933vw;
      height: 32.267vw;
      border-radius: 2.133vw;
      margin-bottom: 2.133vw;
    }
    .name {
      width: 54.933vw;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 4.267vw;
      line-height: 6.4vw;
      margin-bottom: 4.267vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .price {
      margin-bottom: 4.267vw;
      .text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 3.733vw;
        line-height: 5.6vw;
        margin-bottom: 1.067vw;
      }
      .value {
        display: flex;
        align-items: flex-end;
        gap: 1.067vw;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 3.733vw;
        line-height: 5.6vw;
        .currency {
          font-weight: 600;
          font-size: 2.667vw;
          // line-height: 0.972vw;
        }
      }
    }
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .compare {
        background: none;
        border: none;
        cursor: pointer;
        svg {
          width: 5.6vw;
          height: 4vw;
        }
      }
      .logo-and-seller {
        display: flex;
        align-items: center;
        gap: 3.2vw;
        .logo-conatiner {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 6.4vw;
          height: 6.4vw;
          border: 0.267vw solid #e4e6e8;
          border-radius: 1.067vw;
        }
        img {
          width: 4.8vw;
        }
      }
      .seller {
        .seller-name {
          width: 46.4vw;
          font-size: 3.733vw;
          line-height: 4.533vw;
          margin-bottom: 1.067vw;
        }
        .text {
          font-size: 3.2vw;
          line-height: 4vw;
        }
      }
    }
  }
`;
export default CarCard;
