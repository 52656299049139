import React, { useEffect, useState } from "react";
import { axiosCarvenui } from "../../util/integration";
import Form from "../assets/Form";

import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const WorkInfoForm = ({ onSubmit, ...props }) => {
  const [formData, setFormData] = useState({ retired: false });
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(false);
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  useEffect(() => {
    const getBanks = async () => {
      try {
        setLoading(true);
        const res = await axiosCarvenui.get(`/bank`);
        console.log("res", res);
        const data = res.data.results.map((bank) => {
          return {
            ...bank,
            value: bank.id,
            name_ar: bank.name_ar + " - " + bank.name,
          };
        });
        setBanks(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getBanks();
  }, []);

  const workType_field = {
    name: "work_type",
    label: t.carFinance.workInfo.workType,
    displayName: t.carFinance.workInfo.workType,
    type: "select",
    options: [
      {
        name: "Government Employee",
        value: "Government Employee",
        name_ar: "موظف حكومي",
      },
      {
        name: "Private Employee",
        value: "Private Employee",
        name_ar: "موظف خاص",
      },
    ],
  };

  const workDuration_field = {
    name: "work_duration",
    placeholder: t.carFinance.workInfo.workDuration,
    displayName: t.carFinance.workInfo.workDuration,
  };

  const salary_field = {
    name: "salary",
    placeholder: t.carFinance.workInfo.salary,
    displayName: t.carFinance.workInfo.salary,
    displayCurrency: true,
    type : "number"
  };

  const bank_field = {
    name: "payrol_bank",
    label: t.carFinance.workInfo.payrollBank,
    displayName: t.carFinance.workInfo.payrollBank,
    type: "select",
    options: banks,
  };

  const fields = [workType_field, workDuration_field, salary_field, bank_field];

  return (
    <Form
      formData={formData}
      setFormData={setFormData}
      fields={fields}
      submitText={t.next}
      onSubmit={() => {
        onSubmit(formData);
      }}
    />
  );
};

export default WorkInfoForm;
