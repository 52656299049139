import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Form from "../Components/assets/Form";
import { countriesCodes } from "../util/data";
import select_arrow from "../images/select-arrow.svg";
import SelectMenu from "../Components/assets/SelectMenu";
import { ReactComponent as Eye } from "../images/eye.svg";
import { ReactComponent as ClosedEye } from "../images/eye-close.svg";
import Card from "../Components/Auth/Card";
import logo from "../images/logo.svg";
import mail from "../images/auth/success-mail.svg";
import { Link } from "react-router-dom";
import StyledForm from "../Components/assets/StyledForm";
import { useSelector } from "react-redux";
import en from "../util/locales/en";
import ar from "../util/locales/ar";
import { mobileSizes } from "../util/sizes";
import StyledAuthPage from "../Components/Auth/StyledAuthPage";
import { useForm } from "react-hook-form";

const Signup = () => {
  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    mobile: "",
    password1: "",
    password2: "",
  });
  const [password1Type, setPassword1Type] = useState("password");
  const [password2Type, setPassword2Type] = useState("password");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [success, setSuccess] = useState(false);
  const [matching, setMatching] = useState(true);

  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm();

  const registerSubmit = handleSubmit((data) => {
    setSuccess(true);
    console.log("Signup data", data);
  });

  function isArabic(text) {
    var pattern = /[\u0600-\u06FF\u0750-\u077F]/;
    let result = pattern.test(text);
    return result;
  }

  const handleConfirmPassword = () => {
    if (formData.password1 !== formData.password2) {
      setMatching(false);
    } else {
      setMatching(true);
    }
  };

  useEffect(() => {
    console.log("errors", errors);
  }, [errors]);

  useEffect(() => {
    if (formData.password2) {
      handleConfirmPassword();
    } else {
      if (formData.password1 == formData.password2) {
        handleConfirmPassword();
      }
    }
  }, [formData.password1, formData.password2]);

  const isDisabled = () => {
    const disabled = Object.values(formData).some(
      (x) => x === null || x === ""
    );
    return disabled || !matching;
  };
  const codeField = {
    name: "code",
    options: countriesCodes,
  };
  const dataRequired = [
    {
      name: "email",
      placeholder: "Email",
      displayName: "Email",
    },

    {
      name: "first_name",
      placeholder: "First name",
      displayName: "First name",
    },
    {
      name: "last_name",
      placeholder: "Last name",
      displayName: "Last name",
    },
    {
      name: "phone",
      placeholder: "Mobile Number",
      displayName: "Mobile Number",
    },
    {
      name: "code",
      options: countriesCodes,
      type: "select",
    },
  ];
  return (
    <StyledPage language={language}>
      <StyledAuthPage>
        {/* <Form
        fields={dataRequired}
        SubmitText="Continue"
        className="form"
        formData={formData}
        setFormData={setData}
        onSubmit={() => {}}
      /> */}

        <Card page={"signup"} />
        {success ? (
          <div className="success">
            <img src={mail} />
            <div className="success-title">
              {t.authPages.sign_up_page.success_title}
            </div>
            <div className="success-text">
              <p>
                {" "}
                {t.authPages.sign_up_page.success_txt_p1} {formData["email"]}
              </p>
              <p>{t.authPages.sign_up_page.success_txt_p2}</p>
            </div>
          </div>
        ) : (
          <div className="container">
            <Link to="/" className="desktop-only">
              <img src={logo} className="logo" />
            </Link>
            <div className="title">{t.authPages.sign_up_page.header}</div>
            <div className="text desktop-only">
              {t.authPages.sign_up_page.text}
            </div>
            <StyledForm
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="fields">
                <div className="input-div" key="email">
                  {formData["email"] && (
                    <p className="label">{t.authPages.email}</p>
                  )}
                  <input
                    name="email"
                    placeholder={t.authPages.email}
                    type={"text"}
                    required={true}
                    {...register("email", {
                      onChange: (e) =>
                        setFormData({
                          ...formData,

                          email: e.target.value,
                        }),
                      required: {
                        value: true,
                        message: "required",
                      },
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid",
                      },
                    })}
                    className={`${
                      isArabic(formData["email"]) ? "arabic-input" : ""
                    } ${formData["email"] ? "label_active" : ""}`}
                    value={formData["email"] || ""}
                  />
                  {errors["email"]?.message && (
                    <p className="error-text">
                      {errors["email"]?.message != "invalid"
                        ? language == "en"
                          ? `${t.authPages.email} is ${
                              t[errors["email"]?.message]
                            }`
                          : `${t[errors["email"]?.message]} ${
                              t.authPages.email
                            }`
                        : language == "en"
                        ? `${t[errors["email"]?.message]} ${t.authPages.email}`
                        : ` ${t.authPages.email} ${
                            t[errors["email"]?.message]
                          }`}
                    </p>
                  )}
                </div>
                <div className="name-feilds">
                  <div className="input-div" key="first_name">
                    {formData["first_name"] && (
                      <p className="label">{t.authPages.fname}</p>
                    )}
                    <input
                      name="first_name"
                      placeholder={t.authPages.fname}
                      type={"text"}
                      required={true}
                      {...register("first_name", {
                        required: {
                          value: true,
                          message: "required",
                        },
                        onChange: (e) =>
                          setFormData({
                            ...formData,

                            first_name: e.target.value,
                          }),
                      })}
                      className={`${
                        isArabic(formData["first_name"]) ? "arabic-input" : ""
                      } ${formData["first_name"] ? "label_active" : ""}`}
                      value={formData["first_name"] || ""}
                    />
                    {errors["first_name"]?.message && (
                      <p className="error-text">
                        {language == "en"
                          ? `${t.authPages.fname} is ${
                              t[errors["first_name"]?.message]
                            }`
                          : `${t[errors["first_name"]?.message]} ${
                              t.authPages.fname
                            }`}
                      </p>
                    )}
                  </div>
                  <div className="input-div" key="last_name">
                    {formData["last_name"] && (
                      <p className="label">{t.authPages.lname}</p>
                    )}
                    <input
                      name="last_name"
                      placeholder={t.authPages.lname}
                      type={"text"}
                      required={true}
                      {...register("last_name", {
                        required: {
                          value: true,
                          message: "required",
                        },
                        onChange: (e) =>
                          setFormData({
                            ...formData,

                            last_name: e.target.value,
                          }),
                      })}
                      className={`${
                        isArabic(formData["last_name"]) ? "arabic-input" : ""
                      } ${formData["last_name"] ? "label_active" : ""}`}
                      value={formData["last_name"] || ""}
                    />
                    {errors["last_name"]?.message && (
                      <p className="error-text">
                        {language == "en"
                          ? `${t.authPages.lname} is ${
                              t[errors["last_name"]?.message]
                            }`
                          : `${t[errors["last_name"]?.message]} ${
                              t.authPages.lname
                            }`}
                      </p>
                    )}
                  </div>
                </div>

                <div className="input-div" key="mobile">
                  <div className="mobile-input-div">
                    <input
                      name="mobile"
                      placeholder={t.authPages.mobile}
                      // type={"number"}
                      type="text"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      required={true}
                      onKeyDown={(e) => {
                        if (e.key === "e" || e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      {...register("mobile", {
                        required: {
                          value: true,
                          message: "required",
                        },
                        validate: (value) => {
                          if (!isNaN(value) || formData["mobile"]) {
                            return true;
                          } else return "required";
                        },
                        valueAsNumber: true,

                        onChange: (e) => {
                          const value = e.target.value.replace(/\D/g, "");
                          setFormData({
                            ...formData,
                            mobile: value,
                          });
                        },
                      })}
                      value={formData["mobile"] || ""}
                    />
                    <div className="select-code">
                      <SelectMenu
                        field={codeField}
                        value={formData.countryCode}
                        setValue={(value) => {
                          setFormData({
                            ...formData,

                            countryCode: value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  {errors["mobile"]?.message && (
                    <p className="error-text">
                      {language == "en"
                        ? `${t.authPages.mobile} is ${
                            t[errors["mobile"]?.message]
                          }`
                        : `${t[errors["mobile"]?.message]} ${
                            t.authPages.mobile
                          }`}
                    </p>
                  )}
                </div>
                <div className="input-div" key="password1">
                  {formData["password1"] && (
                    <p className="label">{t.authPages.password}</p>
                  )}
                  <input
                    name="password1"
                    placeholder={t.authPages.password}
                    type={password1Type}
                    required={true}
                    {...register("password1", {
                      onChange: (e) =>
                        setFormData({
                          ...formData,
                          password1: e.target.value,
                        }),

                      required: {
                        value: true,
                        message: "required",
                      },
                      // validate: () => {
                      //   if (formData["password1"].length > 0 && matching) {
                      //     setError("password2", null);
                      //   } else {
                      //     console.log(
                      //       formData["password1"],
                      //       formData["password2"]
                      //     );

                      //     if (formData["password2"]==""){
                      //       setError("password2", "required");
                      //     }
                      //   }
                      // },
                    })}
                    className={`password-input ${
                      formData["password1"] ? "label_active" : ""
                    }`}
                    value={formData["password1"] || ""}
                  />
                  <button
                    className="password-icon"
                    onClick={(e) => {
                      e.preventDefault();
                      password1Type == "password"
                        ? setPassword1Type("text")
                        : setPassword1Type("password");
                    }}
                  >
                    {password1Type == "password" ? <ClosedEye /> : <Eye />}
                  </button>
                  {errors["password1"]?.message && (
                    <p className="error-text">
                      {language == "en"
                        ? `${t.authPages.password} is ${
                            t[errors["password1"]?.message]
                          }`
                        : `${t[errors["password1"]?.message]} ${
                            t.authPages.password
                          }`}
                    </p>
                  )}
                </div>
                <div className="input-div" key="password2">
                  {formData["password2"] && (
                    <p className="label">{t.authPages.confirm_password}</p>
                  )}
                  <input
                    name="password2"
                    placeholder={t.authPages.confirm_password}
                    type={password2Type}
                    required={true}
                    {...register("password2", {
                      onChange: (e) =>
                        setFormData({
                          ...formData,
                          password2: e.target.value,
                        }),

                      required: {
                        value: true,
                        message: "required",
                      },
                      validate: (value) =>
                        value === formData["password1"] || "dont_match",
                    })}
                    className={`password-input ${
                      formData["password2"] ? "label_active" : ""
                    }`}
                    value={formData["password2"] || ""}
                  />
                  <button
                    className="password-icon"
                    onClick={(e) => {
                      e.preventDefault();
                      password2Type == "password"
                        ? setPassword2Type("text")
                        : setPassword2Type("password");
                    }}
                  >
                    {password2Type == "password" ? <ClosedEye /> : <Eye />}
                  </button>

                  {errors["password2"]?.message ? (
                    errors["password2"]?.message == "required" ? (
                      <p className="error-text">
                        {language == "en"
                          ? `${t.authPages.confirm_password} is ${
                              t[errors["password2"]?.message]
                            }`
                          : `${t[errors["password2"]?.message]} ${
                              t.authPages.confirm_password
                            }`}
                      </p>
                    ) : (
                      !matching && (
                        <p className="error-text">{t.passwords_dont_match}</p>
                      )
                    )
                  ) : (
                    !matching && (
                      <p className="error-text">{t.passwords_dont_match}</p>
                    )
                  )}
                </div>
                <div className="agree">
                  <input
                    type="radio"
                    id="agree"
                    name="agree"
                    value={true}
                    checked={agreeTerms}
                    onChange={(e) => {
                      setAgreeTerms(e.target.value);
                    }}
                  />
                  <label for="agree">
                    {t.authPages.sign_up_page.agreeTerms}
                  </label>
                </div>
              </div>
              <div className="btn-container">
                <button
                  className="submit"
                  type="submit"
                  onClick={registerSubmit}
                  // disabled={isDisabled() || !agreeTerms}
                  disabled={!agreeTerms}
                >
                  {t.authPages.sign_up_page.continue}
                </button>
              </div>
            </StyledForm>
            <div className="mobile-footer mobile-only">
              <p>{t.authPages.haveAcount}</p>
              <Link to="/login">{t.authPages.sign_in}</Link>
            </div>
          </div>
        )}
      </StyledAuthPage>
    </StyledPage>
  );
};
const StyledPage = styled.div`
  .success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 12.569vw;
      height: 8.611vw;
      margin-bottom: 1.667vw;
    }
    .success-title {
      font-family: ${(props) =>
        props.language == "en" ? "Poppins" : "GE-SS-Bold"};
      font-style: normal;
      font-weight: ${(props) => (props.language == "en" ? "600" : "700")};
      font-size: 1.667vw;
      line-height: 2.5vw;
      color: #0d0a19;
      margin-bottom: 0.833vw;
      @media (min-width: 1440px) {
        font-size: 24px;
        line-height: 36px;
      }
    }
    .success-text {
      p {
        font-family: ${(props) =>
          props.language == "en" ? "Poppins" : "GE-SS-Meduim"};
        font-style: normal;
        font-weight: 500;
        font-size: 0.972vw;
        line-height: 1.458vw;
        text-align: center;
        color: #9a9ea7;
        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }

  .container {
    form {
      .fields {
        .agree {
          display: flex;
          align-items: center;
          gap: 0.903vw;

          label {
            font-family: ${(props) =>
              props.language == "en" ? "Poppins" : "GE-SS-Meduim"};
            font-style: normal;
            font-weight: 500;
            font-size: 0.972vw;
            line-height: 2.333vh;
            color: #9a9ea7;

            @media (min-width: 1440px) {
              font-size: 14px;
              line-height: 21px;
            }
          }
          input {
            width: 2.222vh;
            height: 2.222vh;
            accent-color: #154cd6;
            @media (min-width: 1440px) {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .success {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 48.267vw;
        height: 33.067vw;
        margin-bottom: ${mobileSizes._24px};
      }
      .success-title {
        font-size: ${mobileSizes._20px};
        line-height: ${mobileSizes._30px};
        margin-bottom: ${mobileSizes._16px};
      }
      .success-text {
        p {
          font-size: ${mobileSizes._14px};
          line-height: ${mobileSizes._21px};
        }
      }
    }

    .container {
      form {
        .fields {
          .agree {
            display: flex;
            align-items: center;
            gap: ${mobileSizes._8px};

            label {
              font-weight: ${(props) =>
                props.language == "en" ? "400" : "500"};
              font-size: ${mobileSizes._14px};
              line-height: ${mobileSizes._21px};
            }
            input {
              width: ${mobileSizes._16px};
              height: ${mobileSizes._16px};
              accent-color: #154cd6;
            }
          }
          .select-code {
            flag {
              width: 7.27vw;
              height: 4vw;
            }
          }
        }
      }
    }
  }
`;
export default Signup;
