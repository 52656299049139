import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StyledForm from "../../assets/StyledForm";
import lock from "../../../images/auth/lock.svg";
import { useSelector } from "react-redux";
import en from "../../../util/locales/en";
import ar from "../../../util/locales/ar";
import { useForm } from "react-hook-form";

const Recover = ({ onSubmit, formData, setFormData, isDisabled }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const recoverSubmit = handleSubmit((data) => {
    console.log("form data", data);
    onSubmit();
  });

  return (
    <div className="container">
      <img src={lock} className="section-icon" />
      <div className="title">
        <p>{t.authPages.forget_password_page.recovery.title_line1}</p>
        <p>{t.authPages.forget_password_page.recovery.title_line2}</p>
      </div>
      <div className="text desktop-only">
        {t.authPages.forget_password_page.recovery.text}
      </div>
      <StyledForm
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="fields">
          <div className="input-div" key="email">
            {formData["email"] && <p className="label">{t.authPages.email}</p>}
            <input
              name="email"
              placeholder={t.authPages.email}
              type={"text"}
              required={true}
              {...register("email", {
                onChange: (e) =>
                  setFormData({
                    ...formData,

                    email: e.target.value,
                  }),
                required: {
                  value: true,
                  message: "required",
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "invalid",
                },
              })}
              className={`${formData["email"] ? "active_label" : ""}`}
              value={formData["email"] || ""}
            />
            {errors["email"]?.message && (
              <p className="error-text">
                {errors["email"]?.message != "invalid"
                  ? language == "en"
                    ? `${t.authPages.email} is ${t[errors["email"]?.message]}`
                    : `${t[errors["email"]?.message]} ${t.authPages.email}`
                  : language == "en"
                  ? `${t[errors["email"]?.message]} ${t.authPages.email}`
                  : ` ${t.authPages.email} ${t[errors["email"]?.message]}`}
              </p>
            )}
          </div>
        </div>
        <div className="btn-container">
          <button
            className="submit"
            type="submit"
            onClick={recoverSubmit}
            //  disabled={isDisabled}
          >
            {t.authPages.forget_password_page.recovery.recover}
          </button>
        </div>
      </StyledForm>
    </div>
  );
};

export default Recover;
